// src/containers/LoginPage/RegionInformationSlide.tsx

import { IonButton, IonCol, IonGrid, IonIcon, IonRow } from '@ionic/react'
import React from 'react'
import { Capacitor } from '@capacitor/core'
import { arrowBack, arrowForward } from 'ionicons/icons'
import Swiper from 'swiper'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { AppState } from '../../store/redux/types'
import { RegionState } from '../../store/redux/slices/regionSlice'

interface WelcomeRegionInformationSlideProps {
  swiperInstance: Swiper
  scrollToTop: () => void
}

const WelcomeRegionInformationSlide: React.FC<WelcomeRegionInformationSlideProps> = ({
  swiperInstance,
  scrollToTop,
}) => {
  const { name } = useSelector((state: AppState): RegionState => state.region)
  const { t } = useTranslation()
  return (
    <IonGrid className="loginScreen__region">
      <IonRow>
        <IonCol size-sm="8" offset-sm="2" size-md="6" offset-md="3" size-lg="4" offset-lg="4">
          {Capacitor.getPlatform() === 'ios' && <div>&nbsp;</div>}
          <h2>
            {t('login.regionSelected')} {name}
          </h2>
          <img src="/assets/images/login-account.svg" alt="" className="login halfsize" />
        </IonCol>
      </IonRow>
      <IonRow>
        <IonCol>
          <p>{t('login.accountRegion')}</p>
        </IonCol>
      </IonRow>
      <IonRow>
        <IonCol className="ion-text-center" size="12"></IonCol>
      </IonRow>
      <IonRow className="ion-padding-top fullwidth footer">
        <IonCol className="ion-text-left" size="6">
          <IonButton
            fill="clear"
            onClick={(): void => {
              swiperInstance!.slidePrev()
              scrollToTop()
            }}
          >
            {t('btn.back')} <IonIcon icon={arrowBack} slot="start" aria-hidden={true}></IonIcon>
          </IonButton>
        </IonCol>
        <IonCol className="ion-text-right" size="6">
          <IonButton
            fill="clear"
            onClick={(): void => {
              swiperInstance!.slideNext()
              scrollToTop()
            }}
          >
            {t('btn.continue')} <IonIcon icon={arrowForward} slot="end" aria-hidden={true}></IonIcon>
          </IonButton>
        </IonCol>
      </IonRow>
    </IonGrid>
  )
}

export default WelcomeRegionInformationSlide
