// src/store/redux/slices/newsSlice.ts

import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit'
import { News } from '../../../types/interfaces'
import { AppState } from '../types'
import { NewsAPI } from '../../../api/NewsAPI'
import { WritableDraft } from 'immer'
import { getRegionCode } from '../utils/getRegionCode'
import { getSelectedLanguage } from '../utils/getSelectedLanguage'
import { PURGE } from 'redux-persist/es/constants'

interface NewsObject {
  [key: string]: News
}

export interface NewsState {
  news: NewsObject
  isLoading: boolean
  error: string | null
}

export const initialState: NewsState = {
  news: {},
  isLoading: false,
  error: null,
}

export const fetchNews = createAsyncThunk<NewsObject, void>(
  'news/fetchNews',
  async (_, { getState, rejectWithValue }) => {
    const regionCode = getRegionCode(getState() as AppState)
    const selectedLanguage = getSelectedLanguage(getState() as AppState)
    const newsAPI: NewsAPI = new NewsAPI(regionCode, selectedLanguage)
    try {
      const news: News[] = await newsAPI.getNews()
      // Convert the news array to an object for easier access and manipulation
      return news.reduce((acc: NewsObject, news: News) => {
        acc[news.NewsId] = news
        return acc
      }, {})
    } catch (error) {
      return rejectWithValue(error)
    }
  },
)

export const fetchNewsById = createAsyncThunk<News, string>(
  'news/fetchNewsById',
  async (id: string, { getState, rejectWithValue }) => {
    const regionCode = getRegionCode(getState() as AppState)
    const selectedLanguage = getSelectedLanguage(getState() as AppState)
    const newsAPI: NewsAPI = new NewsAPI(regionCode, selectedLanguage)
    try {
      return await newsAPI.getNewsById(id)
    } catch (error) {
      return rejectWithValue(error)
    }
  },
)

const newsSlice = createSlice({
  name: 'news',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(PURGE, () => initialState)
      .addCase(fetchNews.pending, (state: WritableDraft<NewsState>) => {
        state.isLoading = true
      })
      .addCase(fetchNews.fulfilled, (state: WritableDraft<NewsState>, action: PayloadAction<NewsObject>) => {
        state.news = action.payload
        state.isLoading = false
      })
      .addCase(fetchNews.rejected, (state: WritableDraft<NewsState>, action) => {
        state.error = (action.error.message as string) || null
        state.isLoading = false
      })
      .addCase(fetchNewsById.pending, (state: WritableDraft<NewsState>) => {
        state.isLoading = true
      })
      .addCase(fetchNewsById.fulfilled, (state: WritableDraft<NewsState>, action: PayloadAction<News>) => {
        state.news[action.payload.NewsId] = action.payload
      })
      .addCase(fetchNewsById.rejected, (state: WritableDraft<NewsState>, action) => {
        state.error = (action.error.message as string) || null
      })
  },
})

export default newsSlice.reducer
