import { IonPage, IonContent, IonGrid, IonRow, IonCol } from '@ionic/react'
import React, { useRef, useEffect, useCallback } from 'react'
import type { Swiper as SwiperInterface } from 'swiper'
import { Swiper, SwiperSlide } from 'swiper/react'
import 'swiper/scss'
import '@ionic/react/css/ionic-swiper.css'

import LoginPage from '../auth/LoginPage'
import { useSelector } from 'react-redux'
import { useHistory, useLocation } from 'react-router-dom'
import { AppState } from '../../store/redux/types'
import { RegionState } from '../../store/redux/slices/regionSlice'
import WelcomeSlide from './WelcomeSlide'
import WelcomeSelectRegionSlide from './WelcomeSelectRegionSlide'
import WelcomeRegionInformationSlide from './WelcomeRegionInformationSlide'
import WelcomeAlmostThereSlide from './WelcomeAlmostThereSlide'
import { useFetchLanguages } from '../../hooks/useFetchLanguages'
import useIsSpain from '../../hooks/useIsSpain'

const WelcomePage: React.FC = () => {
  const { name, selectedLanguage } = useSelector((state: AppState): RegionState => state.region)
  const location = useLocation()
  const history = useHistory()
  const isSpain = useIsSpain()
  const queryParams = new URLSearchParams(location.search)
  const slideParam = queryParams.get('slide')
  const initialSlideParam = slideParam ? parseInt(slideParam, 10) : 0

  let initialSlide: number
  if (isSpain) {
    initialSlide = initialSlideParam || 0
  } else {
    initialSlide = !name || !selectedLanguage ? 1 : initialSlideParam
  }

  const swiperRef = useRef<SwiperInterface | null>(null)
  const contentRef = useRef<HTMLIonContentElement | null>(null)

  const scrollToTop = () => {
    contentRef.current?.scrollToTop(500)
  }

  const checkSwiper = useCallback((): void => {
    if (swiperRef.current) {
      if (!isSpain && (!name || !selectedLanguage)) {
        if (slideParam) {
          history.replace('/login')
        }
        if (swiperRef.current.activeIndex >= 1) {
          swiperRef.current.allowSlideNext = false
          swiperRef.current.slideTo(1)
        }
      } else {
        swiperRef.current.allowSlideNext = true
      }
    }
  }, [name, selectedLanguage, isSpain, slideParam, history])

  const handleSlideNext = useCallback(() => {
    if ((isSpain || (name && selectedLanguage)) && swiperRef.current) {
      swiperRef.current.slideNext()
      scrollToTop()
    }
  }, [name, selectedLanguage, isSpain])

  useEffect(() => {
    checkSwiper()
  }, [name, selectedLanguage, isSpain, checkSwiper])

  useEffect(() => {
    handleSlideNext()
  }, [name, selectedLanguage, isSpain, handleSlideNext])

  useFetchLanguages(true, isSpain)

  return (
    <IonPage className="loginScreen">
      <IonContent className="ion-padding" fullscreen ref={contentRef}>
        <IonGrid>
          <IonRow>
            <IonCol size="12" sizeMd="6" offsetMd="3">
              <Swiper
                onSwiper={(swiper: SwiperInterface) => {
                  swiperRef.current = swiper
                  checkSwiper()
                }}
                initialSlide={initialSlide}
                noSwipingClass={'swiper-no-swiping'}
                onSlideChangeTransitionEnd={checkSwiper}
              >
                {/* Always Rendered Slides */}
                <SwiperSlide key={1}>
                  <WelcomeSlide swiperInstance={swiperRef.current!} scrollToTop={scrollToTop} />
                </SwiperSlide>

                {/* Slides Only for Non-Spain Regions */}
                {!isSpain && (
                  <>
                    <SwiperSlide key={2}>
                      <WelcomeSelectRegionSlide swiperInstance={swiperRef.current!} scrollToTop={scrollToTop} />
                    </SwiperSlide>
                    <SwiperSlide key={3}>
                      <WelcomeRegionInformationSlide swiperInstance={swiperRef.current!} scrollToTop={scrollToTop} />
                    </SwiperSlide>
                  </>
                )}

                {/* Common Slides */}
                <SwiperSlide key={5}>
                  <WelcomeAlmostThereSlide swiperInstance={swiperRef.current!} scrollToTop={scrollToTop} />
                </SwiperSlide>
                <SwiperSlide key={6}>
                  <LoginPage />
                </SwiperSlide>
              </Swiper>
            </IonCol>
          </IonRow>
        </IonGrid>
      </IonContent>
    </IonPage>
  )
}

export default WelcomePage
