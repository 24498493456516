import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { AppState } from '../store/redux/types'
import { RegionState } from '../store/redux/slices/regionSlice'
import { ConfigState } from '../store/redux/slices/configSlice'
import { contactEmailDefault } from '../constants/defaults'
import useFetchConfig from './useFetchConfig'

const useContactEmail = () => {
  const [contactEmail, setContactEmail] = useState<string>(contactEmailDefault)
  const { apiCode, selectedLanguage } = useSelector((state: AppState): RegionState => state.region)
  const { config } = useSelector((state: AppState): ConfigState => state.config)

  useFetchConfig()
  useEffect(() => {
    if (config[apiCode] && selectedLanguage && config[apiCode].contactEmail) {
      setContactEmail(config[apiCode].contactEmail)
    }
  }, [config, apiCode, selectedLanguage])

  return contactEmail
}

export default useContactEmail
