// src/api/ProfileAPI.ts

import { BaseAPI } from './BaseAPI'
import { API_PATH } from '../constants/defaults'
import { Profile, ProfileOptions, ProjectLeaderPermissions, User } from '../types/interfaces'

/**
 * ProfileAPI class
 * @classdesc Class for interacting with the profile API
 */
export class ProfileAPI extends BaseAPI {
  /**
   * Get the user profile
   * @returns {Promise<User>} - The profile for the current user
   */
  public async getProfile(): Promise<User> {
    return this.api.get(API_PATH.PROFILE).then((response) => response.data)
  }

  /**
   * Update the profile for the current user
   * @param {Partial<Profile>} data - The profile data to update
   * @returns {Promise<boolean>}
   */
  public async updateProfile(data: Partial<Profile>): Promise<boolean> {
    return this.api.patch(API_PATH.PROFILE, data).then((): boolean => true)
  }

  /**
   * Get the profile options
   * @returns {Promise<ProfileOptions>} - The profile options
   */
  public getProfileOptions = async (): Promise<ProfileOptions> => {
    return this.api.get(API_PATH.PROFILE_OPTIONS).then((response) => response.data)
  }

  /**
   * Get the project leader permissions for the current user
   * @returns {Promise<ProjectLeaderPermissions>} - The project leader permissions
   */
  public async getPermissionsPL(): Promise<ProjectLeaderPermissions> {
    return this.api.get(API_PATH.PROJECT_LEADER_PERMISSIONS).then((response) => response.data)
  }

  /**
   * Delete the profile for the current user
   * @returns {Promise<string>} - The response from the API
   */
  public deleteProfile = async (): Promise<string> => {
    return this.api.post(API_PATH.PROFILE_DELETE).then((response) => response.data)
  }
}
