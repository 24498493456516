// src/store/redux/middleware/networkMiddleware.ts

import { Middleware, isPending } from '@reduxjs/toolkit'
import { ConnectionStatus, Network } from '@capacitor/network'

export const networkMiddleware: Middleware = () => (next) => async (action) => {
  // Check if the action is a pending state
  if (isPending(action)) {
    const status: ConnectionStatus = await Network.getStatus()
    if (!status.connected) {
      return next({
        type: action.type.replace('/pending', '/rejected'),
        error: { message: 'No network connection' },
        meta: { aborted: true },
      })
    }
  }
  return next(action)
}
