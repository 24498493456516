// src/api/ProjectAPI.ts

import { API_PATH } from '../constants/defaults'
import { BaseAPI } from './BaseAPI'
import { ShiftFilter } from '../types/types'
import { Project, ProjectLeaderProject, ProjectLeaderShift, ProjectOptions, Shift } from '../types/interfaces'

interface ApplyToProjectData {
  message: string
}

/**
 * ProjectAPI class
 * @classdesc Class for interacting with the project API
 */
export class ProjectAPI extends BaseAPI {
  /**
   * Get the projects for the current user
   * @returns {Promise<Project[]>} - The projects for the current user
   */
  public async getProjects(): Promise<Project[]> {
    return this.api.get(API_PATH.PROJECTS).then((response) => response.data)
  }

  /**
   * Get a project by ID
   * @param projectId {string} - The project ID
   */
  public async getProject(projectId: string): Promise<Project> {
    return this.api.get(`${API_PATH.PROJECTS}/${projectId}`).then((response) => response.data[0])
  }

  /**
   * Get the projects for the project leader
   * @returns {Promise<ProjectLeaderProject[]>} - The projects for the project leader
   */
  public async getProjectLeaderProjects(): Promise<ProjectLeaderProject[]> {
    return this.api.get(API_PATH.PROJECT_LEADER_PROJECTS).then((response) => response.data)
  }

  /**
   * Get the shifts for the project leader from a project
   * @param projectId {string} - The project ID
   * @returns {Promise<ProjectLeaderShift[]>} - The shifts for the project leader
   */
  public async getProjectLeaderShifts(projectId: string): Promise<ProjectLeaderShift[]> {
    return this.api.get(`${API_PATH.PROJECT_LEADER_PROJECTS}/${projectId}`).then((response) => response.data)
  }

  /**
   * Apply to a project
   * @param projectId {string} - The project ID
   * @param message {string} - The message for the application
   */
  public async applyToProject(projectId: string, message: string): Promise<string> {
    const data: ApplyToProjectData = {
      message,
    }
    return this.api.post(`${API_PATH.PROJECTS}/${projectId}`, data).then((response) => response.data)
  }

  /**
   * Get the shifts for a project
   * @param {string} projectId - The project ID
   * @param {ShiftFilter} shiftFilter - The filter for the shifts
   * @returns {Promise<Shift[]>} - The shifts for the project
   */
  public async getShiftsFromProject(projectId: string, shiftFilter: ShiftFilter = 'all'): Promise<Shift[]> {
    return this.api
      .get(`${API_PATH.PROJECTS}/${projectId}/${API_PATH.SHIFTS}?filter=${shiftFilter}`)
      .then((response) => response.data)
  }

  /**
   * Get the project options
   * @returns {Promise<ProjectOptions>} - The project options
   */
  public async getProjectOptions(): Promise<ProjectOptions> {
    return this.api.get(API_PATH.PROJECTS_OPTIONS).then((response) => response.data)
  }
}
