// src/utils/dateToCustomString.ts

/**
 * Converts a Date to a string
 * @param date {Date} - date to convert
 * @param format {string} - format of the date
 * 'dd/mm/yyyy'(default)|'yyyy/mm/dd'|'dd/mm'|'dd-mm-yyyy'|'shortMonthDate'
 * @returns {string} - date as a string
 */
const dateToCustomString = (date: Date, format: string = 'dd/mm/yyyy'): string => {
  const year: number = date.getFullYear()
  const month: string = (date.getMonth() + 1).toString().padStart(2, '0')
  const day: string = date.getDate().toString().padStart(2, '0')

  switch (format) {
    case 'yyyy/mm/dd':
    case 'yyyy-mm-dd':
    case 'yyyy/mm':
    case 'yyyy-mm':
      return format.replace('yyyy', year.toString()).replace('mm', month).replace('dd', day)
    case 'dd/mm/yyyy':
    case 'dd-mm-yyyy':
      return format.replace('dd', day).replace('mm', month).replace('yyyy', year.toString())
    case 'dd/mm':
    case 'dd-mm':
      return format.replace('dd', day).replace('mm', month)
    case 'shortMonthDate':
      return `${date.toLocaleString('default', { month: 'short' })} ${day}`
    default:
      return `${day}/${month}/${year}`
  }
}

export default dateToCustomString
