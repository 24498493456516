// src/store/redux/store.ts

import { configureStore, Reducer } from '@reduxjs/toolkit'
import { combineReducers } from '@reduxjs/toolkit'
import { persistStore, persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import applicationReducer, { initialState as applicationInitialState } from './slices/applicationSlice'
import authReducer, { initialState as authInitialState } from './slices/authSlice'
import configReducer, { initialState as configInitialState } from './slices/configSlice'
import errorReducer, { initialState as errorInitialState } from './slices/errorSlice'
import newsReducer, { initialState as newsInitialState } from './slices/newsSlice'
import preferencesReducer, { initialState as preferencesInitialState } from './slices/preferencesSlice'
import profileReducer, { initialState as profileInitialState } from './slices/profileSlice'
import projectReducer, { initialState as projectInitialState } from './slices/projectSlice'
import regionReducer, { initialState as regionInitialState } from './slices/regionSlice'
import shiftReducer, { initialState as shiftInitialState } from './slices/shiftSlice'
import toastReducer, { initialState as toastInitialState } from './slices/toastSlice'
import { networkMiddleware } from './middleware/networkMiddleware'
import { errorHandlingMiddleware } from './middleware/errorHandlingMiddleware'
import { Persistor } from 'redux-persist/es/types'
// import { createPartialTransform } from './transforms'
import { rehydrateReducer } from './rehydrateReducer'

// const regionTransform = createPartialTransform(['name', 'apiCode', 'code'])

const regionPersistConfig = {
  key: 'region',
  storage,
  // transforms: [regionTransform],
}

const preferencesPersistConfig = {
  key: 'preferences',
  storage,
}

const rootReducer: Reducer = combineReducers({
  application: rehydrateReducer(applicationReducer, applicationInitialState, 'application'),
  auth: rehydrateReducer(authReducer, authInitialState, 'auth'),
  config: rehydrateReducer(configReducer, configInitialState, 'config'),
  error: rehydrateReducer(errorReducer, errorInitialState, 'error'),
  news: rehydrateReducer(newsReducer, newsInitialState, 'news'),
  preferences: persistReducer(
    preferencesPersistConfig,
    rehydrateReducer(preferencesReducer, preferencesInitialState, 'preferences'),
  ),
  profile: rehydrateReducer(profileReducer, profileInitialState, 'profile'),
  project: rehydrateReducer(projectReducer, projectInitialState, 'project'),
  region: persistReducer(regionPersistConfig, rehydrateReducer(regionReducer, regionInitialState, 'region')),
  shift: rehydrateReducer(shiftReducer, shiftInitialState, 'shift'),
  toast: rehydrateReducer(toastReducer, toastInitialState, 'toast'),
})

const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: ['persist/PERSIST', 'persist/REHYDRATE', 'persist/PAUSE', 'persist/PURGE', 'persist/REGISTER'],
      },
    })
      .concat(networkMiddleware)
      .concat(errorHandlingMiddleware),
})

const persistor: Persistor = persistStore(store)

export { store, persistor }
