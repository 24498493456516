// src/api/AgreementAPI.ts

import { BaseAPI } from './BaseAPI'
import { API_PATH } from '../constants/defaults'
import { Agreement } from '../types/interfaces'
import { AgreementType } from '../types/types'

/**
 * AgreementAPI class
 * @classdesc Class for interacting with the agreement API
 */
export class AgreementAPI extends BaseAPI {
  /**
   * Get the agreement content for a specific agreement type
   * @returns {Promise<Agreement>} - The agreement
   */
  public async getAgreement(agreementType: AgreementType): Promise<Agreement> {
    return this.api.get(`${API_PATH.AGREEMENT}/${agreementType}`).then((response) => response.data)
  }

  /**
   * Update the agreement date for a specific agreement type
   * @param agreementType {AgreementType} - The agreement type
   * @returns {Promise<string>} - The new agreement date
   */
  public async updateAgreementDate(agreementType: AgreementType): Promise<string> {
    return this.api.patch(`${API_PATH.AGREEMENT}/${agreementType}`).then((response) => response.data.date)
  }
}
