import React from 'react'
import { useSelector } from 'react-redux'
import { IonToast } from '@ionic/react'
import { useAppDispatch } from '../hooks/reduxHooks'
import { AppState } from '../store/redux/types'
import { hideToast, ToastState } from '../store/redux/slices/toastSlice'

const ToastNotification: React.FC = () => {
  const dispatch = useAppDispatch()
  const { show, message, duration, color } = useSelector((state: AppState): ToastState => state.toast)

  return (
    <IonToast
      cssClass="bottomToast ion-text-center"
      color={color}
      isOpen={show}
      onDidDismiss={() => dispatch(hideToast())}
      message={message}
      duration={duration}
    />
  )
}

export default ToastNotification
