// src/api/ShiftAPI.ts

import { API_PATH } from '../constants/defaults'
import { BaseAPI } from './BaseAPI'
import { ShiftFilter, ShiftProjectLeaders, VolunteerShiftHourStatus } from '../types/types'
import {
  RecurringShift,
  RecurringShiftFormType,
  RegisterToShiftAPIResponse,
  Shift,
  ShiftFormType,
} from '../types/interfaces'

interface updateVolunteerShiftHourStatusData {
  Status: VolunteerShiftHourStatus
  duration: number
}

/**
 * ShiftAPI class
 * @classdesc Class for interacting with the shift API
 */
export class ShiftAPI extends BaseAPI {
  /**
   * Get the shifts for the current user
   * @param {ShiftFilter} filter - The filter for the shifts
   * @returns {Promise<Shift[]>} - The shifts for the current user
   */
  public async getShifts(filter: ShiftFilter = 'registered'): Promise<Shift[]> {
    return this.api.get(`${API_PATH.SHIFTS}?filter=${filter}`).then((response) => response.data)
  }

  /**
   * Get a shift by ID
   * @param shiftId {string} - The shift ID
   * @returns {Promise<Shift>} - The shift
   */
  public async getShift(shiftId: string): Promise<Shift> {
    return this.api.get(`${API_PATH.SHIFTS}/${shiftId}`).then((response) => response.data[0])
  }

  /**
   * Get the project leaders for a shift
   * @param shiftId {string} - The shift ID
   * @returns {Promise<ShiftProjectLeaders>} - The project leaders for the shift
   */
  public async getShiftProjectLeaders(shiftId: string): Promise<ShiftProjectLeaders> {
    return this.api.get(`${API_PATH.SHIFTS}/${shiftId}/pls`).then((response) => response.data)
  }

  /**
   * Create a recurring shift for a project
   * @param projectId {string} - The project ID
   * @param data {RecurringShiftFormType} - The data to create the recurring shift
   * @returns {Promise<string>} - The recurring shift ID
   */
  public async createRecurringShift(projectId: string, data: RecurringShiftFormType): Promise<string> {
    return this.api
      .post(`${API_PATH.PROJECT_LEADER_PROJECTS}/${projectId}/recurring-shifts`, data)
      .then((response) => response.data.recurringShiftId)
  }

  /**
   * Get a recurring shift from a project by ID
   * @param projectId {string} - The project ID
   * @param recurringShiftId {string} - The recurring shift ID
   * @returns {Promise<RecurringShift>} - The recurring shift
   */
  public async getRecurringShift(projectId: string, recurringShiftId: string): Promise<RecurringShift> {
    return this.api
      .get(`${API_PATH.PROJECT_LEADER_PROJECTS}/${projectId}/recurring-shifts/${recurringShiftId}`)
      .then((response) => response.data)
  }

  /**
   * Update a recurring shift
   * @param projectId {string} - The project ID
   * @param recurringShiftId {string} - The recurring shift ID
   * @param data {RecurringShiftFormType} - The data to update the recurring shift
   */
  public async updateRecurringShift(
    projectId: string,
    recurringShiftId: string,
    data: RecurringShiftFormType,
  ): Promise<string> {
    return this.api
      .patch(`${API_PATH.PROJECT_LEADER_PROJECTS}/${projectId}/recurring-shifts/${recurringShiftId}`, data)
      .then((response) => response.data)
  }

  /**
   * Update the status of a volunteer shift hour
   * @param projectId {string} - The project ID
   * @param shiftId {string} - The shift ID
   * @param hourId {string} - The hour ID
   * @param status {VolunteerShiftHourStatus} - The status to update
   * @param duration {number} - The duration to update
   * @returns {Promise<string>} - The response from the API
   */
  public async updateVolunteerShiftHourStatus(
    projectId: string,
    shiftId: string,
    hourId: string,
    status: VolunteerShiftHourStatus,
    duration: number,
  ): Promise<string> {
    const data: updateVolunteerShiftHourStatusData = {
      Status: status,
      duration,
    }
    return this.api
      .patch(`${API_PATH.PROJECT_LEADER_PROJECTS}/${projectId}/shifts/${shiftId}/${hourId}`, data)
      .then((response) => response.data)
  }

  /**
   * Create a shift
   * @param projectId {string} - The project ID
   * @param data {ShiftFormType} - The data to create the shift
   */
  public async createShift(projectId: string, data: ShiftFormType) {
    return this.api
      .post(`${API_PATH.PROJECT_LEADER_PROJECTS}/${projectId}/shifts`, data)
      .then((response) => response.data)
  }

  /**
   * Update a shift
   * @param projectId {string} - The project ID
   * @param shiftId {string} - The shift ID
   * @param data {ShiftFormType} - The data to update
   * @returns {Promise<string>} - The response from the API
   */
  public async updateShift(projectId: string, shiftId: string, data: ShiftFormType): Promise<string> {
    return this.api
      .patch(`${API_PATH.PROJECT_LEADER_PROJECTS}/${projectId}/shifts/${shiftId}`, data)
      .then((response) => response.data)
  }

  /**
   * Cancel a shift
   * @param projectId {string} - The project ID
   * @param shiftId {string} - The shift ID
   * @returns {Promise<string>} - The response from the API
   */
  public async cancelShift(projectId: string, shiftId: string): Promise<string> {
    return this.api
      .patch(`${API_PATH.PROJECT_LEADER_PROJECTS}/${projectId}/shifts/${shiftId}/cancel`)
      .then((response) => response.data)
  }

  /**
   * Register to a shift
   * @param shiftId {string} - The shift ID
   * @returns {Promise<RegisterToShiftAPIResponse>} - The response from the API
   */
  public async registerToShift(shiftId: string): Promise<RegisterToShiftAPIResponse> {
    return this.api.post(`${API_PATH.SHIFTS}/${shiftId}`).then((response) => response.data)
  }

  /**
   * Unregister from a shift
   * @param shiftId {string} - The shift ID
   * @returns {Promise<string>} - The response from the API
   */
  public async unregisterFromShift(shiftId: string): Promise<string> {
    return this.api.delete(`${API_PATH.SHIFTS}/${shiftId}`).then((response) => response.data)
  }
}
