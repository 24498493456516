import { useLocation } from 'react-router-dom'
import { useEffect } from 'react'
import { LanguageCodeValues } from '../constants/defaults'
import { LanguageCode, RegionApiCodes } from '../types/types'
import { getLanguagesLocal } from '../utils/getLanguagesLocal'
import { setLanguage } from '../store/redux/slices/regionSlice'
import { useAppDispatch } from './reduxHooks'

/**
 * Set the language based on the regionApiCode
 * @param regionApiCode {RegionApiCodes} - The regionApiCode
 */
const useSetLanguage = (regionApiCode: RegionApiCodes) => {
  const dispatch = useAppDispatch()
  const location = useLocation()
  useEffect(() => {
    const params = new URLSearchParams(location.search)
    let lang = params.get('lang')
    if (lang && !LanguageCodeValues.includes(lang as LanguageCode)) {
      lang = 'en-GB'
    }
    const languages = getLanguagesLocal(regionApiCode)
    const languagesKeys = Object.keys(languages || {})
    if (languages && lang && languagesKeys.includes(lang)) {
      dispatch(setLanguage(lang as LanguageCode))
    } else {
      dispatch(setLanguage(languagesKeys[0] as LanguageCode))
    }
  }, [dispatch, location.search, regionApiCode])
}

export default useSetLanguage
