import parsePhoneNumber from 'libphonenumber-js'

/**
 * Format phone number to international format
 * @param phoneNumber {string} The phone number to format
 * @returns {string} The formatted phone number
 */
export const formatPhoneNumber = (phoneNumber: string): string => {
  let newMobile = phoneNumber
  const formattedPhone = parsePhoneNumber(phoneNumber)
  if (formattedPhone) {
    newMobile = formattedPhone.formatInternational()
  }
  return newMobile
}
