// src/hooks/useNativeKeyboard.ts

import { Capacitor } from '@capacitor/core'
import { Keyboard } from '@capacitor/keyboard'

export const useNativeKeyboard = () => {
  return (): void => {
    if (Capacitor.getPlatform() !== 'web') {
      Keyboard.setAccessoryBarVisible({ isVisible: true })
        .then((r: void) => r)
        .catch((err) => console.error('Failed to set keyboard accessory bar:', err))
    }
  }
}
