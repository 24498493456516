import {
  IonButton,
  IonCol,
  IonGrid,
  IonIcon,
  IonRow,
  IonSelect,
  IonSelectOption,
  SelectCustomEvent,
} from '@ionic/react'
import { Capacitor } from '@capacitor/core'
import { API_BASE_REGION } from '../../constants/defaults'
import { arrowBack, arrowForward } from 'ionicons/icons'
import React, { useState } from 'react'
import { fetchLanguagesLocal, RegionState, setLanguage, setRegion } from '../../store/redux/slices/regionSlice'
import { useAppDispatch } from '../../hooks/reduxHooks'
import Swiper from 'swiper'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { AppState } from '../../store/redux/types'
import { Region } from '../../types/interfaces'
import { LanguageCode } from '../../types/types'
import { ConfigState } from '../../store/redux/slices/configSlice'
import useFetchLocalConfig from '../../hooks/useFetchLocalConfig'

interface WelcomeSelectRegionSlideProps {
  swiperInstance: Swiper
  scrollToTop: () => void
}

const WelcomeSelectRegionSlide: React.FC<WelcomeSelectRegionSlideProps> = ({ swiperInstance, scrollToTop }) => {
  const dispatch = useAppDispatch()
  const { name, apiCode, languages, selectedLanguage } = useSelector((state: AppState): RegionState => state.region)
  const { localConfig } = useSelector((state: AppState): ConfigState => state.config)
  const { t } = useTranslation()
  // Unable to use isLoading from the regionSlice because it doesn't work with iOS and Android
  const [isLoading, setIsLoading] = useState<boolean>(false)
  useFetchLocalConfig()

  const selectRegionHandler = async (event: SelectCustomEvent) => {
    const value = event.detail.value
    const selectedRegion: Region | undefined = API_BASE_REGION.find((el: Region): boolean => el.apiCode === value)
    const regionData: Region = {
      name: selectedRegion?.name || '',
      apiCode: selectedRegion?.apiCode || (localConfig.UAT ? 'belgium-uat' : 'belgium'),
      timeZone: selectedRegion?.timeZone || 'Europe/Brussels',
      environment: selectedRegion?.environment || 'uat',
    }
    if (selectedRegion) {
      setIsLoading(true)
      dispatch(setRegion(regionData))
      dispatch(setLanguage(null))
      dispatch(fetchLanguagesLocal(selectedRegion.apiCode))
      setIsLoading(false)
    }
  }

  const availableRegions = (uat: boolean): Region[] => {
    return API_BASE_REGION.filter(
      (region: Region) =>
        (uat && region.environment === 'uat') ||
        (!uat && region.environment === 'production' && region.apiCode !== 'spain'),
    )
  }

  const selectRegionLanguageHandler = (event: SelectCustomEvent): void => {
    const value = event.detail.value as LanguageCode
    dispatch(setLanguage(value))
  }

  return (
    <IonGrid className="loginScreen__region">
      <IonRow className="fullwidth">
        <IonCol
          className="ion-text-center"
          size-sm="8"
          offset-sm="2"
          size-md="6"
          offset-md="3"
          size-lg="4"
          offset-lg="4"
        >
          {Capacitor.getPlatform() === 'ios' && <div>&nbsp;</div>}
          <h2>{t('login.selectRegionTitle')}</h2>
        </IonCol>
      </IonRow>
      <IonRow>
        <IonCol>
          <p>{t('login.selectRegion')}</p>

          <img src="/assets/images/login-select-region.svg" alt="" className="login" />
        </IonCol>
      </IonRow>
      <IonRow className="fullwidth">
        <IonCol className="ion-text-center" size="12" offset-sm="0">
          <IonSelect
            label={t('login.selectRegionLabel')}
            interface="popover"
            className="ion-margin-top"
            interfaceOptions={{
              cssClass: 'custom-dropdown custom-dropdown--login',
            }}
            onIonChange={selectRegionHandler}
            value={apiCode}
          >
            {availableRegions(localConfig.UAT).map((region: Region) => (
              <IonSelectOption className="regionOption" key={region.apiCode} value={region.apiCode}>
                {region.name}
              </IonSelectOption>
            ))}
          </IonSelect>
          <IonSelect
            label={t('login.selectLanguageLabel')}
            interface="popover"
            className="ion-margin-top"
            interfaceOptions={{
              cssClass: 'custom-dropdown custom-dropdown--login',
            }}
            placeholder={isLoading ? t('loading.languages') : ''}
            onIonChange={selectRegionLanguageHandler}
            disabled={!name || isLoading}
            value={selectedLanguage}
          >
            {languages &&
              Object.keys(languages).map((key) => (
                <IonSelectOption className="regionOption" key={key} value={key}>
                  {languages[key as LanguageCode]}
                </IonSelectOption>
              ))}
          </IonSelect>
        </IonCol>
      </IonRow>
      <IonRow className="ion-padding-top fullwidth footer">
        <IonCol className="ion-text-left" size="6">
          <IonButton
            fill="clear"
            onClick={(): void => {
              swiperInstance!.slidePrev()
              scrollToTop()
            }}
          >
            {t('btn.back')} <IonIcon icon={arrowBack} slot="start" aria-hidden={true}></IonIcon>
          </IonButton>
        </IonCol>
        <IonCol className="ion-text-right" size="6">
          <IonButton
            disabled={!name || !selectedLanguage}
            fill="clear"
            onClick={(): void => {
              swiperInstance!.slideNext()
              scrollToTop()
            }}
          >
            {t('btn.continue')} <IonIcon icon={arrowForward} slot="end" aria-hidden={true}></IonIcon>
          </IonButton>
        </IonCol>
      </IonRow>
    </IonGrid>
  )
}

export default WelcomeSelectRegionSlide
