import React from 'react'
import { IonLoading, IonPage } from '@ionic/react'
import { useTranslation } from 'react-i18next'

const LoadingFallback: React.FC = () => {
  const { t } = useTranslation()
  return (
    <IonPage>
      <IonLoading isOpen={true} message={t('loading.loading')} spinner="crescent" duration={500} translucent={true} />
    </IonPage>
  )
}

export default LoadingFallback
