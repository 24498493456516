// src/components/LogoImage.tsx
import React from 'react'
import { IonImg } from '@ionic/react'
import { useTranslation } from 'react-i18next'
import { useTheme } from '../store/themeContext'

const LogoImage: React.FC = () => {
  const { t } = useTranslation()
  const { darkMode } = useTheme()
  const logoSrc: string = darkMode ? '/assets/images/servenow-dark.svg' : '/assets/images/servenow-light.svg'
  return <IonImg src={logoSrc} className="logo mx-auto ion-padding-vertical" alt={t('images.logoAlt')} />
}

export default LogoImage
