// utils/saveCredentialsToKeychain.ts

import { Capacitor } from '@capacitor/core'
import { SavePassword } from 'capacitor-ios-autofill-save-password'

/**
 * Save the user's credentials to the iOS keychain
 * @param username {string} - The username
 * @param password {string} - The password
 */
export const saveCredentialsToKeychain = async (username: string, password: string) => {
  if (Capacitor.getPlatform() === 'ios') {
    try {
      await SavePassword.promptDialog({
        username,
        password,
      })
    } catch (error) {
      console.error('Failed to save password', error)
    }
  }
}
