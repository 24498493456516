import ToastNotification from './components/ToastNotification'
import React, { Suspense, useEffect, useRef, useState } from 'react'
import { Route, Switch, useLocation } from 'react-router-dom'
import i18next from 'i18next'
import { SplashScreen } from '@capacitor/splash-screen'

import { useAppDispatch } from './hooks/reduxHooks'
import { AppState } from './store/redux/types'
import { useSelector } from 'react-redux'
import { AuthState, initialiseUser } from './store/redux/slices/authSlice'
import { fetchLocalConfig } from './store/redux/slices/configSlice'

import LoadingFallback from './components/LoadingFallback'
import ErrorDisplay from './components/ErrorDisplay'
import ActivateAccount from './features/auth/ActivateAccount'
import UpdatePasswordForm from './features/auth/UpdatePasswordForm'
import UpdateEmail from './features/auth/UpdateEmail'
import { hideToast, showToast } from './store/redux/slices/toastSlice'
import { useTranslation } from 'react-i18next'
import { RegionState } from './store/redux/slices/regionSlice'

// Lazy loading is applied to the AuthenticatedApp to optimize initial load time
// for unauthenticated users, since it is the larger part of the app.
// The UnauthenticatedApp is not lazy-loaded to ensure the login/signup UI
// is rendered immediately, which helps avoid potential CSS loading issues
// experienced when both were lazy-loaded.

const AuthenticatedApp = React.lazy(() => import('./features/auth/AuthenticatedApp'))
import UnauthenticatedApp from './features/auth/UnauthenticatedApp'

const Main: React.FC = () => {
  const dispatch = useAppDispatch()
  const { isAuth } = useSelector((state: AppState): AuthState => state.auth)
  const { selectedLanguage } = useSelector((state: AppState): RegionState => state.region)
  const location = useLocation()
  const [isInitialized, setIsInitialized] = useState(false)
  const { t } = useTranslation()

  useEffect(() => {
    if (selectedLanguage) {
      i18next.changeLanguage(selectedLanguage).then(() => {
        if (isAuth) {
          dispatch(hideToast())
          dispatch(showToast({ message: t('more.languageChanged') }))
        }
      })
    }
  }, [selectedLanguage, dispatch, isAuth])

  const hasFetchedUser = useRef(false)
  useEffect(() => {
    async function initializeApp() {
      await SplashScreen.show()
      // Define paths that should skip user initialization
      const skipInitPaths = ['/activate', '/update-password', '/update-email']
      const shouldSkipInit = skipInitPaths.some((path) => location.pathname.startsWith(path))
      if (!shouldSkipInit) {
        await dispatch(fetchLocalConfig())
        if (!hasFetchedUser.current) {
          await dispatch(initialiseUser())
          hasFetchedUser.current = true
        }
      }
      document.body.style.display = 'block'
      setIsInitialized(true)
      await SplashScreen.hide()
    }

    if (!isInitialized) {
      initializeApp().then((r) => r)
    }
  }, [dispatch, location.pathname])

  return (
    <>
      {!isInitialized ? (
        <></>
      ) : (
        <>
          <ErrorDisplay />
          <ToastNotification />
          <Suspense fallback={<LoadingFallback />}>
            <Switch>
              <Route path="/activate/:regionApiCode/:email/:token" component={ActivateAccount} />
              <Route path="/update-password/:regionApiCode/:email/:token" component={UpdatePasswordForm} />
              <Route path="/update-email/:regionApiCode/:id/:oldEmail/:newEmail" component={UpdateEmail} />
              <Route path="/" component={isAuth ? AuthenticatedApp : UnauthenticatedApp} />
            </Switch>
          </Suspense>
        </>
      )}
    </>
  )
}

export default Main
