import React from 'react'
import { IonCol, IonGrid, IonItem, IonLabel, IonRow } from '@ionic/react'
import { useTranslation } from 'react-i18next'

const FooterCommon: React.FC = () => {
  const { t } = useTranslation()
  return (
    <IonGrid className="text-sm text-center">
      <IonRow>
        <IonCol>
          <IonItem lines="none">
            <IonLabel color="medium" className="text-sm text-center">
              <p>{t('footer.developedBy')}</p>
              <p>{t('footer.address')}</p>
            </IonLabel>
          </IonItem>
        </IonCol>
      </IonRow>
    </IonGrid>
  )
}

export default FooterCommon
