// src/store/redux/slices/shiftSlice.ts

import { createAsyncThunk, createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit'
import { AppState } from '../types'
import { ShiftAPI } from '../../../api/ShiftAPI'
import { WritableDraft } from 'immer'
import { getRegionCode } from '../utils/getRegionCode'
import { RecurringShift, RecurringShiftFormType, Shift, ShiftFormType } from '../../../types/interfaces'
import { ShiftProjectLeaders, VolunteerShiftHourStatus } from '../../../types/types'
import {
  cancelShiftLocal,
  fetchShiftsPL,
  updateShiftLocal,
  updateShiftPresenceLocal,
  updateShiftRegistrationStatusLocal,
  updateVolunteerShiftHourStatusLocal,
} from './projectSlice'
import { getSelectedLanguage } from '../utils/getSelectedLanguage'
import { PURGE } from 'redux-persist/es/constants'
import { fetchProfile, ProfileState } from './profileSlice'
import { addYears, format, intervalToDuration } from 'date-fns'

interface ShiftObject {
  [key: string]: Shift
}

interface ShiftProjectLeadersObject {
  [key: string]: ShiftProjectLeaders
}

interface RecurringShiftObject {
  [key: string]: RecurringShift
}

export interface ShiftState {
  shifts: ShiftObject
  shiftProjectLeaders: ShiftProjectLeadersObject
  recurringShifts: RecurringShiftObject
  isLoading: boolean
  error: string | null
}

export const initialState: ShiftState = {
  shifts: {},
  shiftProjectLeaders: {},
  recurringShifts: {},
  isLoading: false,
  error: null,
}

interface FetchShiftsPayload {
  shiftId: string
  shift: Shift
}

interface ShiftsProjectLeadersPayload {
  shiftId: string
  shiftProjectLeaders: ShiftProjectLeaders
}

interface CreateRecurringShiftPayload {
  projectId: string
  data: RecurringShiftFormType
}

interface FetchRecurringShiftPayload {
  projectId: string
  recurringShiftId: string
}

interface UpdateRecurringShiftAction {
  recurringShiftId: string
  data: RecurringShiftFormType
}

interface UpdateRecurringShiftPayload {
  projectId: string
  recurringShiftId: string
  data: RecurringShiftFormType
}

interface UpdateVolunteerShiftHourStatusPayload {
  projectId: string
  shiftId: string
  hourId: string
  status: VolunteerShiftHourStatus
  duration: number
}

interface CreateShiftPayload {
  projectId: string
  data: ShiftFormType
}

interface UpdateShiftPayload {
  projectId: string
  shiftId: string
  data: ShiftFormType
}

interface CancelShiftPayload {
  projectId: string
  shiftId: string
}

export const getRegisteredShifts = createSelector(
  (state: AppState) => state.shift.shifts,
  (shifts: ShiftObject) => {
    // Convert the object of shifts to an array and filter for registered shifts
    return Object.values(shifts).filter((shift: Shift) => shift.Registered)
  },
)

export const fetchShifts = createAsyncThunk<ShiftObject, void>(
  'shifts/fetchShifts',
  async (_, { getState, rejectWithValue }) => {
    const regionCode = getRegionCode(getState() as AppState)
    const selectedLanguage = getSelectedLanguage(getState() as AppState)
    const shiftAPI: ShiftAPI = new ShiftAPI(regionCode, selectedLanguage)
    try {
      const shifts: Shift[] = await shiftAPI.getShifts()
      // Convert the shift array to an object for easier access and manipulation
      return shifts.reduce((acc: ShiftObject, shift: Shift) => {
        acc[shift.ShiftId] = shift
        return acc
      }, {})
    } catch (error) {
      return rejectWithValue(error)
    }
  },
)

export const fetchShift = createAsyncThunk<FetchShiftsPayload, string>(
  'shifts/fetchShift',
  async (shiftId: string, { getState, rejectWithValue }) => {
    const regionCode = getRegionCode(getState() as AppState)
    const selectedLanguage = getSelectedLanguage(getState() as AppState)
    const shiftAPI: ShiftAPI = new ShiftAPI(regionCode, selectedLanguage)
    try {
      const shift: Shift = await shiftAPI.getShift(shiftId)
      return { shiftId, shift }
    } catch (error) {
      return rejectWithValue(error)
    }
  },
)

export const fetchShiftsProjectLeaders = createAsyncThunk<ShiftsProjectLeadersPayload, string>(
  'shifts/fetchShiftsProjectLeaders',
  async (shiftId: string, { getState, rejectWithValue }) => {
    const regionCode = getRegionCode(getState() as AppState)
    const shiftAPI: ShiftAPI = new ShiftAPI(regionCode)
    try {
      const shiftProjectLeaders: ShiftProjectLeaders = await shiftAPI.getShiftProjectLeaders(shiftId)
      return { shiftId, shiftProjectLeaders }
    } catch (error) {
      return rejectWithValue(error)
    }
  },
)

export const createRecurringShift = createAsyncThunk<undefined, CreateRecurringShiftPayload>(
  'shifts/createRecurringShift',
  async (payload: CreateRecurringShiftPayload, { getState, dispatch, rejectWithValue }) => {
    const { projectId, data } = payload
    const regionCode = getRegionCode(getState() as AppState)
    const shiftAPI: ShiftAPI = new ShiftAPI(regionCode)
    try {
      await shiftAPI.createRecurringShift(projectId, data)
      dispatch(fetchShiftsPL(projectId))
    } catch (error) {
      return rejectWithValue(error)
    }
  },
)

export const fetchRecurringShift = createAsyncThunk<RecurringShift, FetchRecurringShiftPayload>(
  'shifts/fetchRecurringShift',
  async (payload: FetchRecurringShiftPayload, { getState, rejectWithValue }) => {
    const { projectId, recurringShiftId } = payload
    const regionCode = getRegionCode(getState() as AppState)
    const shiftAPI: ShiftAPI = new ShiftAPI(regionCode)
    try {
      return await shiftAPI.getRecurringShift(projectId, recurringShiftId)
    } catch (error) {
      return rejectWithValue(error)
    }
  },
)

export const updateRecurringShift = createAsyncThunk<UpdateRecurringShiftAction, UpdateRecurringShiftPayload>(
  'shifts/updateRecurringShift',
  async (payload, { getState, dispatch, rejectWithValue }) => {
    const { projectId, recurringShiftId, data } = payload
    const regionCode = getRegionCode(getState() as AppState)
    const shiftAPI: ShiftAPI = new ShiftAPI(regionCode)
    try {
      await shiftAPI.updateRecurringShift(projectId, recurringShiftId, data)
      dispatch(fetchShiftsPL(projectId))
      return { recurringShiftId, data }
    } catch (error) {
      return rejectWithValue(error)
    }
  },
)

export const cancelRecurringShift = createAsyncThunk<undefined, FetchRecurringShiftPayload>(
  'shifts/cancelRecurringShift',
  async (payload: FetchRecurringShiftPayload, { getState, dispatch, rejectWithValue }) => {
    const { projectId, recurringShiftId } = payload
    const regionCode = getRegionCode(getState() as AppState)
    const shiftAPI: ShiftAPI = new ShiftAPI(regionCode)
    try {
      const data = {
        stopDate: format(addYears(new Date(), -10), 'yyyy-MM-dd'),
      }
      await shiftAPI.updateRecurringShift(projectId, recurringShiftId, data)
      dispatch(fetchShiftsPL(projectId))
    } catch (error) {
      return rejectWithValue(error)
    }
  },
)

export const updateVolunteerShiftHourStatus = createAsyncThunk<undefined, UpdateVolunteerShiftHourStatusPayload>(
  'shifts/updateVolunteerShiftHourStatus',
  async (payload: UpdateVolunteerShiftHourStatusPayload, { getState, dispatch, rejectWithValue }) => {
    const { projectId, shiftId, hourId, status, duration } = payload
    const regionCode = getRegionCode(getState() as AppState)
    const shiftAPI: ShiftAPI = new ShiftAPI(regionCode)
    try {
      await shiftAPI.updateVolunteerShiftHourStatus(projectId, shiftId, hourId, status, duration)
      dispatch(
        updateVolunteerShiftHourStatusLocal({
          projectId,
          shiftId,
          hourId,
          status,
        }),
      )
    } catch (error) {
      return rejectWithValue(error)
    }
  },
)

export const createShift = createAsyncThunk<undefined, CreateShiftPayload>(
  'shifts/createShift',
  async ({ projectId, data }, { getState, dispatch, rejectWithValue }) => {
    const regionCode = getRegionCode(getState() as AppState)
    const shiftAPI: ShiftAPI = new ShiftAPI(regionCode)
    try {
      await shiftAPI.createShift(projectId, data)
      dispatch(fetchShiftsPL(projectId))
    } catch (error) {
      return rejectWithValue(error)
    }
  },
)

export const updateShift = createAsyncThunk<undefined, UpdateShiftPayload>(
  'shifts/updateShift',
  async (payload: UpdateShiftPayload, { getState, dispatch, rejectWithValue }) => {
    const { projectId, shiftId, data } = payload
    const regionCode = getRegionCode(getState() as AppState)
    const shiftAPI: ShiftAPI = new ShiftAPI(regionCode)

    try {
      await shiftAPI.updateShift(projectId, shiftId, data)
      dispatch(
        updateShiftLocal({
          projectId,
          shiftId,
          data,
        }),
      )
    } catch (error) {
      return rejectWithValue(error)
    }
  },
)

export const cancelShift = createAsyncThunk<undefined, CancelShiftPayload>(
  'shifts/cancelShift',
  async (payload: CancelShiftPayload, { getState, dispatch, rejectWithValue }) => {
    const { projectId, shiftId } = payload
    const regionCode = getRegionCode(getState() as AppState)
    const shiftAPI: ShiftAPI = new ShiftAPI(regionCode)
    try {
      await shiftAPI.cancelShift(projectId, shiftId)
      dispatch(cancelShiftLocal({ projectId, shiftId }))
    } catch (error) {
      return rejectWithValue(error)
    }
  },
)

interface RegisterUnregisterPayload {
  shiftId: string
  projectId: string
}

export const registerToShift = createAsyncThunk<undefined, RegisterUnregisterPayload>(
  'shifts/registerToShift',
  async (payload: RegisterUnregisterPayload, { getState, dispatch, rejectWithValue }) => {
    const { shiftId, projectId } = payload
    const regionCode = getRegionCode(getState() as AppState)
    // Fetch the profile before registering to the shift to check if the user is blocked
    await dispatch(fetchProfile())
    const profile = (getState() as AppState).profile as ProfileState
    if (profile.profile.Status === 'Blocked') {
      return rejectWithValue('Blocked')
    }
    const shiftAPI: ShiftAPI = new ShiftAPI(regionCode)
    try {
      await shiftAPI.registerToShift(shiftId)
      dispatch(
        updateShiftRegistrationStatusLocal({
          projectId,
          shiftId,
          status: true,
        }),
      )
      dispatch(updateShiftPresenceLocal({ projectId, shiftId, presence: true }))
      dispatch(fetchShifts())
    } catch (error) {
      return rejectWithValue(error)
    }
  },
)

export const unregisterFromShift = createAsyncThunk<undefined, RegisterUnregisterPayload>(
  'shifts/unregisterFromShift',
  async (payload: RegisterUnregisterPayload, { getState, dispatch, rejectWithValue }) => {
    const { shiftId, projectId } = payload
    const regionCode = getRegionCode(getState() as AppState)
    const shiftAPI: ShiftAPI = new ShiftAPI(regionCode)
    try {
      await shiftAPI.unregisterFromShift(shiftId)
      dispatch(
        updateShiftRegistrationStatusLocal({
          projectId,
          shiftId,
          status: false,
        }),
      )
      dispatch(updateShiftPresenceLocal({ projectId, shiftId, presence: false }))
      dispatch(fetchShifts())
    } catch (error) {
      return rejectWithValue(error)
    }
  },
)

const shiftsSlice = createSlice({
  name: 'shift',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(PURGE, () => initialState)
      .addCase(fetchShifts.pending, (state: WritableDraft<ShiftState>) => {
        state.isLoading = true
      })
      .addCase(fetchShifts.fulfilled, (state: WritableDraft<ShiftState>, action: PayloadAction<ShiftObject>) => {
        state.shifts = action.payload
        state.isLoading = false
      })
      .addCase(fetchShifts.rejected, (state: WritableDraft<ShiftState>, action) => {
        state.error = (action.error.message as string) || null
        state.isLoading = false
      })
      .addCase(fetchShift.pending, (state: WritableDraft<ShiftState>) => {
        state.isLoading = true
      })
      .addCase(fetchShift.fulfilled, (state: WritableDraft<ShiftState>, action: PayloadAction<FetchShiftsPayload>) => {
        if (action.payload.shift) {
          state.shifts[action.payload.shiftId] = action.payload.shift
        }
        state.isLoading = false
      })
      .addCase(fetchShift.rejected, (state: WritableDraft<ShiftState>, action) => {
        state.error = (action.error.message as string) || null
        state.isLoading = false
      })
      .addCase(fetchShiftsProjectLeaders.pending, (state: WritableDraft<ShiftState>) => {
        state.isLoading = true
      })
      .addCase(
        fetchShiftsProjectLeaders.fulfilled,
        (state: WritableDraft<ShiftState>, action: PayloadAction<ShiftsProjectLeadersPayload>) => {
          state.shiftProjectLeaders[action.payload.shiftId] = action.payload.shiftProjectLeaders
          state.isLoading = false
        },
      )
      .addCase(fetchShiftsProjectLeaders.rejected, (state: WritableDraft<ShiftState>, action) => {
        state.error = (action.error.message as string) || null
        state.isLoading = false
      })
      .addCase(createRecurringShift.pending, (state: WritableDraft<ShiftState>) => {
        state.isLoading = true
      })
      .addCase(createRecurringShift.fulfilled, (state: WritableDraft<ShiftState>) => {
        state.isLoading = false
      })
      .addCase(createRecurringShift.rejected, (state: WritableDraft<ShiftState>, action) => {
        state.error = (action.error.message as string) || null
        state.isLoading = false
      })
      .addCase(fetchRecurringShift.pending, (state: WritableDraft<ShiftState>) => {
        state.isLoading = true
      })
      .addCase(fetchRecurringShift.fulfilled, (state: WritableDraft<ShiftState>, action) => {
        state.recurringShifts[action.payload.id] = action.payload
        state.isLoading = false
      })
      .addCase(fetchRecurringShift.rejected, (state: WritableDraft<ShiftState>, action) => {
        state.error = (action.error.message as string) || null
        state.isLoading = false
      })
      .addCase(updateRecurringShift.pending, (state: WritableDraft<ShiftState>) => {
        state.isLoading = true
      })
      .addCase(updateRecurringShift.fulfilled, (state: WritableDraft<ShiftState>, action) => {
        const recurringShift = state.recurringShifts[action.payload.recurringShiftId]
        if (recurringShift) {
          const startDateTime = action.payload.data.startDateTime
          const endDateTime = action.payload.data.endDateTime
          if (startDateTime && endDateTime) {
            const interval = intervalToDuration({
              start: new Date(startDateTime),
              end: new Date(endDateTime),
            })
            recurringShift.duration = (interval.hours || 0) + (interval.minutes || 0) / 60
          }
          recurringShift.startDateTime = startDateTime || recurringShift.startDateTime
          recurringShift.stopDate = action.payload.data.stopDate || recurringShift.stopDate
          recurringShift.volunteersNeeded = action.payload.data.volunteersNeeded || recurringShift.volunteersNeeded
          recurringShift.description = action.payload.data.description || recurringShift.description
          recurringShift.daysOfWeek = action.payload.data.daysOfWeek || recurringShift.daysOfWeek
          recurringShift.weeklyOccurrence = action.payload.data.weeklyOccurrence || recurringShift.weeklyOccurrence
        }
        state.isLoading = false
      })
      .addCase(updateRecurringShift.rejected, (state: WritableDraft<ShiftState>, action) => {
        state.error = (action.error.message as string) || null
        state.isLoading = false
      })
      .addCase(cancelRecurringShift.pending, (state: WritableDraft<ShiftState>) => {
        state.isLoading = true
      })
      .addCase(cancelRecurringShift.fulfilled, (state: WritableDraft<ShiftState>) => {
        state.isLoading = false
      })
      .addCase(cancelRecurringShift.rejected, (state: WritableDraft<ShiftState>, action) => {
        state.error = (action.error.message as string) || null
        state.isLoading = false
      })
      .addCase(updateVolunteerShiftHourStatus.pending, (state: WritableDraft<ShiftState>) => {
        state.isLoading = true
      })
      .addCase(updateVolunteerShiftHourStatus.fulfilled, (state: WritableDraft<ShiftState>) => {
        state.isLoading = false
      })
      .addCase(updateVolunteerShiftHourStatus.rejected, (state: WritableDraft<ShiftState>, action) => {
        state.error = (action.error.message as string) || null
        state.isLoading = false
      })
      .addCase(updateShift.pending, (state: WritableDraft<ShiftState>) => {
        state.isLoading = true
      })
      .addCase(updateShift.fulfilled, (state: WritableDraft<ShiftState>) => {
        state.isLoading = false
      })
      .addCase(updateShift.rejected, (state: WritableDraft<ShiftState>, action) => {
        state.error = (action.error.message as string) || null
        state.isLoading = false
      })
      .addCase(registerToShift.pending, (state: WritableDraft<ShiftState>) => {
        state.isLoading = true
      })
      .addCase(registerToShift.fulfilled, (state: WritableDraft<ShiftState>) => {
        state.isLoading = false
      })
      .addCase(registerToShift.rejected, (state: WritableDraft<ShiftState>, action) => {
        state.error = (action.error.message as string) || null
        state.isLoading = false
      })
      .addCase(unregisterFromShift.pending, (state: WritableDraft<ShiftState>) => {
        state.isLoading = true
      })
      .addCase(unregisterFromShift.fulfilled, (state: WritableDraft<ShiftState>) => {
        state.isLoading = false
      })
      .addCase(unregisterFromShift.rejected, (state: WritableDraft<ShiftState>, action) => {
        state.error = (action.error.message as string) || null
        state.isLoading = false
      })
  },
})

export default shiftsSlice.reducer
