import { ErrorMessage } from '@hookform/error-message'
import {
  IonLabel,
  IonItem,
  IonInput,
  IonButton,
  InputCustomEvent,
  IonDatetime,
  DatetimeCustomEvent,
} from '@ionic/react'
import React, { useRef } from 'react'
import { useForm, Controller } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import parsePhoneNumber from 'libphonenumber-js'
import { isBefore, sub } from 'date-fns'

import { useAppDispatch } from '../../../hooks/reduxHooks'
import { AuthState, setSignUpData } from '../../../store/redux/slices/authSlice'
import { useNativeKeyboard } from '../../../hooks/useNativeKeyboard'
import { useSelector } from 'react-redux'
import { AppState } from '../../../store/redux/types'
import { validateSpanishDNI } from '../../../utils/validateSpanishDNI'
import dateToCustomString from '../../../utils/dateToCustomString'
import { formatPhoneNumber } from '../../../utils/formatPhoneNumber'
import { RegionState } from '../../../store/redux/slices/regionSlice'
import useIsSpain from '../../../hooks/useIsSpain'

type UserDetailsFormProps = {
  nextStep: () => void
}

interface UserDetailsFormInputs {
  firstname: string
  lastname: string
  birthdate: string
  id: string
  mobile: string
  email: string
  password: string
  confirmPassword: string
}

const UserDetailsForm: React.FC<UserDetailsFormProps> = ({ nextStep }) => {
  const dispatch = useAppDispatch()
  const { signUpData } = useSelector((state: AppState): AuthState => state.auth)
  const { selectedLanguage } = useSelector((state: AppState): RegionState => state.region)
  const { t } = useTranslation()
  const handleNativeKeyboard = useNativeKeyboard()
  const isSpain = useIsSpain()

  const {
    handleSubmit,
    control,
    watch,
    formState: { errors },
  } = useForm<UserDetailsFormInputs>({
    defaultValues: {
      firstname: signUpData?.firstname || '',
      lastname: signUpData?.lastname || '',
      birthdate: signUpData?.birthdate || '',
      id: signUpData?.id || '',
      mobile: signUpData?.mobile || '',
      email: signUpData?.email || '',
      password: signUpData?.password || '',
      confirmPassword: signUpData?.password || '',
    },
  })
  const password = useRef<string | null | undefined>('')
  password.current = watch('password', '')

  const submitHandler = async (data: UserDetailsFormInputs) => {
    const { firstname, lastname, birthdate, id, mobile, email, password } = data
    const newMobile = formatPhoneNumber(mobile)
    dispatch(setSignUpData({ firstname, lastname, birthdate, id, mobile: newMobile, email, password }))
    nextStep()
  }

  return (
    <>
      <h1 className="ion-padding-vertical">{`${t('signup.personalInformation')} - ${t('signup.step1')}`}</h1>
      <form onSubmit={handleSubmit(submitHandler)}>
        <IonItem className="ion-no-padding">
          <Controller
            control={control}
            name="firstname"
            render={({ field: { onChange, onBlur, value } }) => (
              <IonInput
                label={`${t('forms.firstName')} *`}
                labelPlacement="stacked"
                onBlur={onBlur}
                onIonChange={(e) => onChange(e.detail.value ?? '')}
                onIonFocus={handleNativeKeyboard}
                autocapitalize="on"
                placeholder={t('forms.placeholder.firstName')}
                enterkeyhint="next"
                required={true}
                inputMode="text"
                value={value ?? ''}
              />
            )}
            rules={{
              required: `${t('forms.required')}`,
              pattern: {
                // eslint-disable-next-line no-misleading-character-class
                value: /^[\p{L} -j́]*$/gu,
                message: t('forms.invalidFirstName'),
              },
            }}
          />
        </IonItem>
        <ErrorMessage
          errors={errors}
          name="firstname"
          as={<IonLabel className="validationErrorMsg ion-text-wrap" color="danger" />}
        />
        <IonItem className="ion-no-padding">
          <Controller
            control={control}
            name="lastname"
            render={({ field: { onChange, onBlur, value } }) => (
              <IonInput
                label={`${t('forms.lastNames')} *`}
                labelPlacement="stacked"
                onBlur={onBlur}
                onIonChange={(e) => onChange(e.detail.value ?? '')}
                onIonFocus={handleNativeKeyboard}
                autocapitalize="on"
                placeholder={t('forms.placeholder.lastNames')}
                enterkeyhint="next"
                required={true}
                inputMode="text"
                value={value ?? ''}
              />
            )}
            rules={{
              required: `${t('forms.required')}`,
              pattern: {
                // eslint-disable-next-line no-misleading-character-class
                value: /^[\p{L} -j́]*$/gu,
                message: t('forms.invalidLastNames'),
              },
            }}
          />
        </IonItem>
        <ErrorMessage
          errors={errors}
          name="lastname"
          as={<IonLabel className="validationErrorMsg ion-text-wrap" color="danger" />}
        />
        <IonItem className="ion-no-padding">
          <Controller
            control={control}
            name="birthdate"
            render={({ field: { onChange, onBlur, value } }) => (
              <IonDatetime
                color="primary"
                presentation="date"
                preferWheel
                size="cover"
                onBlur={onBlur}
                onIonChange={(event: DatetimeCustomEvent) => {
                  const dateSelected = event.detail.value!.toString()
                  onChange(dateToCustomString(new Date(dateSelected), 'yyyy-mm-dd'))
                }}
                onIonFocus={handleNativeKeyboard}
                locale={selectedLanguage || 'en-GB'}
                value={value ?? ''}
              >
                <span slot="title">{`${t('forms.birthdate')} *`}</span>
              </IonDatetime>
            )}
            rules={{
              required: `${t('forms.required')}`,
              validate: (value) => {
                if (!value) {
                  return t('forms.required')
                }
                const birthdate = new Date(value)
                const nowMinus18 = sub(new Date(), { years: 18 })
                if (isBefore(birthdate, nowMinus18)) {
                  return true
                }
                return t('forms.olderThan18')
              },
            }}
          />
        </IonItem>
        <ErrorMessage
          errors={errors}
          name="birthdate"
          as={<IonLabel className="validationErrorMsg ion-text-wrap" color="danger" />}
        />
        <IonItem className="ion-no-padding">
          <Controller
            control={control}
            name="id"
            render={({ field: { onChange, onBlur, value } }) => (
              <IonInput
                type="text"
                label={`${t('forms.idNumber')}${isSpain ? ' *' : ''}`}
                labelPlacement="stacked"
                onBlur={onBlur}
                onIonChange={(e) => onChange(e.detail.value ?? '')}
                onIonFocus={handleNativeKeyboard}
                placeholder={isSpain ? '12345678R' : ''}
                enterkeyhint="next"
                autocapitalize="characters"
                inputMode="text"
                required={isSpain}
                value={value ?? ''}
              />
            )}
            rules={{
              required: isSpain ? `${t('forms.required')}` : false,
              validate: (value) => {
                if (isSpain) {
                  if (!value) {
                    return t('forms.required')
                  }
                  if (!validateSpanishDNI(value)) {
                    return t('forms.invalidId')
                  }
                }
                return true
              },
            }}
          />
        </IonItem>
        <ErrorMessage
          errors={errors}
          name="id"
          as={<IonLabel className="validationErrorMsg ion-text-wrap" color="danger" />}
        />
        <IonItem className="ion-no-padding">
          <Controller
            control={control}
            name="mobile"
            render={({ field: { onChange, onBlur, value } }) => (
              <IonInput
                type="tel"
                label={`${t('forms.phoneNumber')} *`}
                labelPlacement="stacked"
                onBlur={onBlur}
                onIonChange={(e) => onChange(e.detail.value ?? '')}
                onIonFocus={handleNativeKeyboard}
                placeholder={t('forms.placeholder.phoneNumber')}
                enterkeyhint="next"
                autocapitalize="off"
                inputMode="tel"
                required={true}
                value={value ?? ''}
              />
            )}
            rules={{
              required: `${t('forms.required')}`,
              validate: (value) => {
                if (!value) {
                  return t('forms.required')
                }
                const formattedPhone = parsePhoneNumber(value)
                if (!formattedPhone || !formattedPhone.isValid()) {
                  return t('forms.invalidPhoneNumber')
                }
                return true
              },
            }}
          />
        </IonItem>
        <ErrorMessage
          errors={errors}
          name="mobile"
          as={<IonLabel className="validationErrorMsg ion-text-wrap" color="danger" />}
        />
        <IonItem className="ion-no-padding">
          <Controller
            control={control}
            name="email"
            render={({ field: { onChange, onBlur, value } }) => (
              <IonInput
                type="email"
                label={`${t('forms.emailAddress')} *`}
                labelPlacement="stacked"
                onBlur={onBlur}
                onIonChange={(e) => onChange(e.detail.value ?? '')}
                onIonFocus={handleNativeKeyboard}
                placeholder={t('forms.placeholder.email')}
                enterkeyhint="next"
                autocapitalize="off"
                inputMode="email"
                required={true}
                value={value ?? ''}
              />
            )}
            rules={{
              required: `${t('forms.required')}`,
              pattern: {
                value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,24}$/i,
                message: t('forms.invalidEmail'),
              },
            }}
          />
        </IonItem>
        <ErrorMessage
          errors={errors}
          name="email"
          as={<IonLabel className="validationErrorMsg ion-text-wrap" color="danger" />}
        />
        <IonItem className="ion-no-padding">
          <Controller
            control={control}
            name="password"
            render={({ field: { onChange, onBlur, value } }) => (
              <IonInput
                type="password"
                label={`${t('forms.password')} *`}
                labelPlacement="stacked"
                onBlur={onBlur}
                onIonChange={(event: InputCustomEvent) => {
                  onChange(event.detail.value)
                  password.current = event.detail.value
                }}
                onIonFocus={handleNativeKeyboard}
                placeholder={t('forms.enterPassword')}
                enterkeyhint="next"
                required={true}
                inputMode="text"
                value={value ?? ''}
              />
            )}
            rules={{
              required: `${t('forms.required')}`,
              pattern: {
                value: /^(?=.*[a-z])(?=.*\d)[a-zA-Z\w\W]{8,}$/,
                message: t('forms.invalidPassword'),
              },
            }}
          />
        </IonItem>
        <ErrorMessage
          errors={errors}
          name="password"
          as={<IonLabel className="validationErrorMsg ion-text-wrap" color="danger" />}
        />
        <IonItem className="ion-no-padding">
          <Controller
            control={control}
            name="confirmPassword"
            render={({ field: { onChange, onBlur, value } }) => (
              <IonInput
                type="password"
                label={`${t('forms.confirmPassword')} *`}
                labelPlacement="stacked"
                onBlur={onBlur}
                onIonChange={(e) => onChange(e.detail.value ?? '')}
                onIonFocus={handleNativeKeyboard}
                placeholder={t('forms.confirmPassword')}
                enterkeyhint="send"
                required={true}
                inputMode="text"
                value={value ?? ''}
              />
            )}
            rules={{
              required: `${t('forms.required')}`,
              validate: (value): boolean => {
                return value === watch('password')
              },
            }}
          />
        </IonItem>
        <ErrorMessage
          errors={errors}
          message={t('login.unmatchingPasswords')}
          name="confirmPassword"
          as={<IonLabel className="validationErrorMsg ion-text-wrap" color="danger" />}
        />
        <IonButton className="ion-margin-top" expand="block" type="submit">
          {t('btn.next')}
        </IonButton>
      </form>
    </>
  )
}

export default UserDetailsForm
