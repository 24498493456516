import { ErrorMessage } from '@hookform/error-message'
import {
  IonLabel,
  IonButton,
  IonInput,
  IonGrid,
  IonRow,
  IonCol,
  IonModal,
  IonLoading,
  IonRippleEffect,
} from '@ionic/react'
import React, { useState } from 'react'
import { useForm, Controller } from 'react-hook-form'

import { useTranslation } from 'react-i18next'

import ResetPasswordForm from './ResetPasswordForm'
import LogoImage from '../../components/LogoImage'

import styles from './styles/LoginPage.module.scss'
import { useSelector } from 'react-redux'
import { useAppDispatch } from '../../hooks/reduxHooks'
import { AuthState, login } from '../../store/redux/slices/authSlice'
import { AppState } from '../../store/redux/types'
import { useNativeKeyboard } from '../../hooks/useNativeKeyboard'
import { saveCredentialsToKeychain } from '../../utils/saveCredentialsToKeychain'

interface LoginFormInputs {
  email: string
  password: string
}

const LoginPage: React.FC = () => {
  const { isLoading } = useSelector((state: AppState): AuthState => state.auth)
  const dispatch = useAppDispatch()
  const [showResetModal, setShowResetModal] = useState(false)
  const { t } = useTranslation()
  const handleNativeKeyboard = useNativeKeyboard()

  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm<LoginFormInputs>()

  const submitHandler = async (data: LoginFormInputs) => {
    const { email, password } = data
    const resultAction = await dispatch(login({ email, password }))
    if (login.fulfilled.match(resultAction)) {
      await saveCredentialsToKeychain(email, password)
    }
  }

  return (
    <>
      <IonLoading isOpen={isLoading} message={t('loading.sending')} spinner="crescent" translucent={true} />
      <IonGrid className="loginScreen__form">
        <IonRow className="fullwidth">
          <IonCol
            className="ion-text-center"
            size-sm="12"
            offset-sm="0"
            size-md="8"
            offset-md="2"
            size-lg="8"
            offset-lg="2"
          >
            <h2>&nbsp;</h2>
            <LogoImage />
          </IonCol>
        </IonRow>
        <IonRow className="fullwidth">
          <IonCol size-sm="12" offset-sm="0" size-md="8" offset-md="2" size-lg="8" offset-lg="2">
            <form onSubmit={handleSubmit(submitHandler)}>
              <div className={styles.inputField}>
                <Controller
                  control={control}
                  name="email"
                  defaultValue=""
                  render={({ field: { onChange } }) => (
                    <IonInput
                      className={styles.inputField}
                      label={t('forms.emailAddress')}
                      labelPlacement="stacked"
                      fill="outline"
                      type="email"
                      onIonChange={onChange}
                      placeholder="example@mail.com"
                      autocomplete="username"
                      enterkeyhint="next"
                      onIonFocus={handleNativeKeyboard}
                      required={true}
                    ></IonInput>
                  )}
                />
                <ErrorMessage
                  errors={errors}
                  name="email"
                  as={<IonLabel className="validationErrorMsg ion-text-wrap" color="danger" />}
                />
              </div>
              <div className={styles.inputField}>
                <Controller
                  control={control}
                  name="password"
                  defaultValue=""
                  render={({ field: { onChange } }) => (
                    <IonInput
                      className={styles.inputField}
                      label={t('forms.password')}
                      labelPlacement="stacked"
                      fill="outline"
                      type="password"
                      onIonInput={onChange}
                      autocomplete="current-password"
                      onIonFocus={handleNativeKeyboard}
                      enterkeyhint="send"
                      placeholder={t('forms.enterPassword')}
                    ></IonInput>
                  )}
                  rules={{ required: `${t('forms.required')}` }}
                />
                <ErrorMessage
                  errors={errors}
                  name="password"
                  as={<IonLabel className="validationErrorMsg ion-text-wrap" color="danger" />}
                />
              </div>
              <IonButton expand="block" type="submit" className="ion-margin-top ion-activatable ripple-parent">
                {t('btn.logIn')}
                <IonRippleEffect />
              </IonButton>
            </form>
          </IonCol>
        </IonRow>
        <IonRow className="fullwidth ion-padding-top ion-padding-bottom">
          <IonCol size-sm="12" offset-sm="0" size-md="8" offset-md="2" size-lg="8" offset-lg="2">
            <div className="ion-text-center">
              <IonButton
                className="ion-no-margin ion-no-padding button-small"
                onClick={() => setShowResetModal(true)}
                fill="clear"
              >
                {t('login.forgotPassword')}
              </IonButton>
            </div>
          </IonCol>
        </IonRow>
        <IonRow className="fullwidth">
          <IonCol size-sm="12" offset-sm="0" size-md="8" offset-md="2" size-lg="8" offset-lg="2">
            <IonButton
              expand="block"
              className="ion-margin-top"
              fill="clear"
              color="dark"
              routerDirection="forward"
              routerLink="/signup"
            >
              {t('btn.createAccount')} &nbsp;
              <span className="primary"> {t('signup.signup')}</span>
            </IonButton>
          </IonCol>
        </IonRow>
      </IonGrid>
      <IonModal isOpen={showResetModal} aria-labelledby="modalTitle" keyboardClose>
        <ResetPasswordForm onClose={() => setShowResetModal(false)} />
      </IonModal>
    </>
  )
}

export default LoginPage
