import React, { useEffect, useRef, useState } from 'react'
import { useParams } from 'react-router-dom'
import { useAppDispatch } from '../../hooks/reduxHooks'
import { AuthState, updateEmail } from '../../store/redux/slices/authSlice'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { AppState } from '../../store/redux/types'
import { RegionApiCodes } from '../../types/types'
import ActionPage from './ActionPage'
import useSetLanguage from '../../hooks/useSetLanguage'

interface UpdateEmailParams {
  regionApiCode: RegionApiCodes
  id: string
  oldEmail: string
  newEmail: string
}

interface Payload {
  response: {
    status: number
  }
}

const UpdateEmail: React.FC = () => {
  const dispatch = useAppDispatch()
  const { isLoading } = useSelector((state: AppState): AuthState => state.auth)
  const { regionApiCode, id, oldEmail, newEmail } = useParams<UpdateEmailParams>()
  const [message, setMessage] = useState('')
  const { t } = useTranslation()
  useSetLanguage(regionApiCode)

  const hasTriedToUpdateEmail = useRef(false)
  useEffect(() => {
    if (!hasTriedToUpdateEmail.current) {
      hasTriedToUpdateEmail.current = true
      dispatch(updateEmail({ regionApiCode, id, oldEmail, newEmail })).then((result) => {
        if (result.meta.requestStatus === 'fulfilled') {
          setMessage(t('updateEmail.success'))
        } else {
          const payload: Payload = result.payload as Payload
          switch (payload.response.status) {
            case 400:
              setMessage(t('updateEmail.malformed'))
              break
            case 404:
              setMessage(t('updateEmail.notFound'))
              break
            default:
              setMessage(t('updateEmail.error'))
              break
          }
        }
      })
    }
  }, [id, oldEmail, newEmail, dispatch, t, regionApiCode])

  return <ActionPage title={t('updateEmail.title')} isLoading={isLoading} message={message} />
}

export default UpdateEmail
