// src/api/ConfigAPI.ts

import { API_PATH } from '../constants/defaults'
import { Config } from '../types/interfaces'
import { BaseAPI } from './BaseAPI'

/**
 * ConfigAPI class
 * @classdesc Class for interacting with the config API
 */
export class ConfigAPI extends BaseAPI {
  /**
   * Get the config
   * @returns {Promise<Config>} - The config
   */
  public async getConfig(): Promise<Config> {
    return this.api.get(API_PATH.CONFIG).then((response) => response.data)
  }
}
