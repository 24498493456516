// src/store/redux/rehydrateReducer.ts

import { Reducer, UnknownAction } from '@reduxjs/toolkit'

interface RehydrateAction extends UnknownAction {
  payload?: { [key: string]: unknown }
}

export const rehydrateReducer = <S>(reducer: Reducer<S>, initialState: S, sliceName: string): Reducer<S> => {
  return (state: S | undefined, action: RehydrateAction): S => {
    if (action.type === 'persist/REHYDRATE' && action.payload) {
      // Ensure the slice state matches the expected type S
      const sliceState = action.payload[sliceName] as S | undefined
      if (sliceState) {
        return { ...initialState, ...sliceState }
      }
    }
    return reducer(state, action)
  }
}
