// src/store/redux/slices/errorSlice.ts

import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { WritableDraft } from 'immer'

export interface ErrorState {
  title: string
  message: string
  actionType: string
  isVisible?: boolean
}

export const initialState: ErrorState = {
  title: '',
  message: '',
  actionType: '',
  isVisible: false,
}

const errorSlice = createSlice({
  name: 'error',
  initialState,
  reducers: {
    setError(state: WritableDraft<ErrorState>, action: PayloadAction<ErrorState>) {
      state.title = action.payload.title
      state.message = action.payload.message
      state.actionType = action.payload.actionType
      state.isVisible = true
    },
    clearError(state: WritableDraft<ErrorState>) {
      state.title = ''
      state.message = ''
      state.actionType = ''
      state.isVisible = false
    },
  },
})

export const { setError, clearError } = errorSlice.actions
export default errorSlice.reducer
