import { IonRouterOutlet } from '@ionic/react'
import { Redirect, Route } from 'react-router'
import React, { useEffect } from 'react'

import useStatusBarStyle from '../../hooks/useStatusBarStyle'
import WelcomePage from '../welcome/WelcomePage'
import SignUpPage from './SignUpPage'
import useSetRegionLanguageSpain from '../../hooks/useSetRegionLanguageSpain'
import useIsSpain from '../../hooks/useIsSpain'
import { useSelector } from 'react-redux'
import { AppState } from '../../store/redux/types'
import { RegionState } from '../../store/redux/slices/regionSlice'
import { useHistory } from 'react-router-dom'

const UnauthenticatedApp: React.FC = () => {
  useStatusBarStyle()
  const history = useHistory()
  const isSpain = useIsSpain()
  useSetRegionLanguageSpain(isSpain)
  const { selectedLanguage } = useSelector((state: AppState): RegionState => state.region)

  // If not Spain and no language selected, redirect right away to avoid
  // ever hitting SignUpPage in a wrong state.
  useEffect(() => {
    if (!isSpain && !selectedLanguage) {
      history.replace('/login')
    }
  }, [isSpain, selectedLanguage, history])

  const redirect = !isSpain ? '/login?slide=4' : '/login'

  return (
    <IonRouterOutlet>
      <Route exact path="/login" component={WelcomePage} />
      <Route exact path="/signup" component={SignUpPage} />
      <Route render={() => <Redirect to={redirect} />} />
    </IonRouterOutlet>
  )
}

export default UnauthenticatedApp
