import { useSelector } from 'react-redux'
import { AppState } from '../store/redux/types'
import { ConfigState } from '../store/redux/slices/configSlice'
import useFetchLocalConfig from './useFetchLocalConfig'

const useIsSpain = () => {
  const { localConfig } = useSelector((state: AppState): ConfigState => state.config)
  useFetchLocalConfig()
  return localConfig.REGION === 'spain'
}

export default useIsSpain
