import React, { useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { App, URLOpenListenerEvent } from '@capacitor/app'
import { PluginListenerHandle } from '@capacitor/core'

const AppUrlListener: React.FC = () => {
  const history = useHistory()
  useEffect(() => {
    let listener: PluginListenerHandle | undefined

    const setUpListener = async () => {
      listener = await App.addListener('appUrlOpen', (event: URLOpenListenerEvent) => {
        console.log('AppUrlListener event.url:', event.url)

        try {
          const url = new URL(event.url)
          const slug = url.pathname
          console.log('AppUrlListener slug:', slug)

          if (slug) {
            history.push(slug)
          }
        } catch (error) {
          console.error('Error parsing URL:', error)
        }
      })
    }

    setUpListener().then(() => console.log('AppUrlListener setup complete'))

    return () => {
      if (listener && typeof listener.remove === 'function') {
        listener.remove().then(() => console.log('AppUrlListener removed'))
      }
    }
  }, [history])

  return null
}

export default AppUrlListener
