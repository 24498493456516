// src/store/redux/slices/toastSlice.ts
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { WritableDraft } from 'immer'

export interface ToastState {
  show: boolean
  message: string
  duration?: number
  color?: string
}

export const initialState: ToastState = {
  show: false,
  message: '',
  duration: 3000,
  color: 'primary',
}

interface ToastPayload {
  message: string
  duration?: number
  color?: string
}

const toastSlice = createSlice({
  name: 'toast',
  initialState,
  reducers: {
    showToast: (state: WritableDraft<ToastState>, action: PayloadAction<ToastPayload>): void => {
      state.show = true
      state.message = action.payload.message
      if (action.payload.duration) {
        state.duration = action.payload.duration
      }
      if (action.payload.color) {
        state.color = action.payload.color
      }
    },
    hideToast: (state: WritableDraft<ToastState>): void => {
      state.show = initialState.show
      state.message = initialState.message
      state.duration = initialState.duration
      state.color = initialState.color
    },
  },
})

export const { showToast, hideToast } = toastSlice.actions
export default toastSlice.reducer
