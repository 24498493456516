// src/api/BaseAPI.ts

import axios, { AxiosInstance, AxiosResponse, InternalAxiosRequestConfig } from 'axios'
import getBearerToken from '../utils/getBearerToken'
import { LocalConfig, loadConfig } from '../utils/loadConfig'
import { LanguageCode, RegionApiCodes } from '../types/types'
import { defaultLocalConfig } from '../constants/defaults'

let config: LocalConfig = defaultLocalConfig
loadConfig().then((cfg) => {
  config = cfg
})

/**
 * BaseAPI class
 * @classdesc Class for interacting with the backend API
 */
export class BaseAPI {
  protected api: AxiosInstance
  lang: LanguageCode | null = null

  /**
   * Constructor for the BaseAPI class
   * @param {RegionApiCodes} regionApiCode - The region code for the API
   * @param {LanguageCode | null} lang - The language code
   */
  constructor(regionApiCode: RegionApiCodes, lang: LanguageCode | null = null) {
    const apiBaseURL = config.API_BASE_URL
    if (!apiBaseURL) {
      throw new Error('API_BASE_URL is not defined')
    }
    this.api = axios.create({
      baseURL: `${apiBaseURL}/${regionApiCode}`,
      headers: {
        'Content-Type': 'application/json',
      },
    })
    this.lang = lang

    this.setupInterceptors()
  }

  private setupInterceptors(): void {
    // Request interceptor for adding authentication token
    this.api.interceptors.request.use(
      async (config: InternalAxiosRequestConfig) => {
        // Add the token if it exists
        if (!config.headers.Authorization) {
          const token: string = await getBearerToken()
          if (token) {
            config.headers.Authorization = `Bearer ${token}`
          }
        }
        // If the lang is set, add it to the query params
        if (this.lang) {
          config.params = { ...config.params, lang: this.lang }
        }
        return config
      },
      (error) => {
        return Promise.reject(error)
      },
    )

    // Response interceptor for handling errors
    this.api.interceptors.response.use(
      (response: AxiosResponse) => response,
      (error) => {
        if (error.response) {
          // HTTP status code errors
          console.error('Response error:', error.response)
          // Handle specific status codes here (e.g., 401, 403)
          if (error.response.status === 401) {
            // Handle unauthorized access, if necessary
          }
        } else if (error.request) {
          // Network errors (no response received)
          console.error('Network error:', error.message)
        } else {
          // Other errors
          console.error('Other error:', error.message)
        }
        // Ensure the error is propagated to middleware
        return Promise.reject(error)
      },
    )
  }
}
