// src/components/common/ErrorDisplay

import React from 'react'
import { useSelector } from 'react-redux'
import { AppState } from '../store/redux/types'
import { clearError, ErrorState } from '../store/redux/slices/errorSlice'
import { useAppDispatch } from '../hooks/reduxHooks'
import { IonAlert } from '@ionic/react'
import { useTranslation } from 'react-i18next'

const ErrorDisplay: React.FC = () => {
  const { title, message, isVisible } = useSelector((state: AppState): ErrorState => state.error)
  const dispatch = useAppDispatch()
  const { t } = useTranslation()

  return (
    <IonAlert
      isOpen={isVisible}
      onDidDismiss={() => dispatch(clearError())}
      header={title}
      message={message}
      buttons={[
        {
          text: t('btn.close'),
          handler: () => dispatch(clearError()),
        },
      ]}
    />
  )
}

export default ErrorDisplay
