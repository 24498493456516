import { useEffect } from 'react'
import { Region } from '../types/interfaces'
import { API_BASE_REGION } from '../constants/defaults'
import { setLanguage, setRegion } from '../store/redux/slices/regionSlice'
import { useAppDispatch } from './reduxHooks'

const useSetRegionLanguageSpain = (isSpain: boolean) => {
  if (!isSpain) return
  const dispatch = useAppDispatch()
  useEffect(() => {
    const selectedRegion: Region = API_BASE_REGION.find((el: Region): boolean => el.apiCode === 'spain') as Region
    const regionData: Region = {
      name: selectedRegion.name,
      apiCode: selectedRegion.apiCode,
      timeZone: selectedRegion.timeZone,
      environment: selectedRegion.environment,
    }
    dispatch(setRegion(regionData))
    dispatch(setLanguage('es-ES'))
  }, [dispatch])
}

export default useSetRegionLanguageSpain
