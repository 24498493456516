import { useEffect, useRef } from 'react'
import { ConfigState, fetchLocalConfig } from '../store/redux/slices/configSlice'
import { useAppDispatch } from './reduxHooks'
import { useSelector } from 'react-redux'
import { AppState } from '../store/redux/types'

const useFetchLocalConfig = () => {
  const dispatch = useAppDispatch()
  const { localConfig } = useSelector((state: AppState): ConfigState => state.config)

  const hasFetchedLocalConfig = useRef(false)
  useEffect(() => {
    if (!localConfig && !hasFetchedLocalConfig.current) {
      dispatch(fetchLocalConfig())
      hasFetchedLocalConfig.current = true
    }
  }, [dispatch, localConfig])
}

export default useFetchLocalConfig
