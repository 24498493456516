import { Languages, RegionApiCodes } from '../types/types'

/**
 * It returns the languages for a given region code
 * @param regionCode {RegionApiCodes} The region code
 * @returns {Languages | null} The languages for the region code
 */
export const getLanguagesLocal = (regionCode: RegionApiCodes): Languages | null => {
  const languages = {
    belgium: {
      'en-GB': 'English',
      'nl-BE': 'Nederlands',
      'fr-BE': 'Français',
    },
    'belgium-uat': {
      'en-GB': 'English',
      'nl-BE': 'Nederlands',
      'fr-BE': 'Français',
    },
    france: {
      'en-GB': 'English',
    },
    'france-uat': {
      'en-GB': 'English',
    },
    ireland: {
      'en-IE': 'English',
    },
    'ireland-uat': {
      'en-IE': 'English',
    },
    portugal: {
      'en-GB': 'English',
    },
    'portugal-uat': {
      'en-GB': 'English',
    },
    spain: {
      'es-ES': 'Español',
    },
    'spain-uat': {
      'es-ES': 'Español',
    },
  } as { [key in RegionApiCodes]: Languages }
  return languages[regionCode] || null
}
