import React from 'react'
import { IonButton, IonCol, IonContent, IonGrid, IonPage, IonRow } from '@ionic/react'
import { useTranslation } from 'react-i18next'
import FooterCommon from '../../components/FooterCommon'
import LogoImage from '../../components/LogoImage'
import { Capacitor } from '@capacitor/core'

interface AsyncActionPageProps {
  title: string
  isLoading: boolean
  message: string
}

const ActionPage: React.FC<AsyncActionPageProps> = ({ title, isLoading, message }) => {
  const { t } = useTranslation()
  return (
    <IonPage className="ion-margin-top">
      <IonContent>
        <IonGrid>
          <IonRow>
            <IonCol className="text-center">
              <h2>&nbsp;</h2>
              {Capacitor.getPlatform() === 'ios' && <div>&nbsp;</div>}
              <LogoImage />
              <h1 className="ion-padding-vertical">{title}</h1>
              {isLoading ? <p>{t('loading.loading')}</p> : <p>{message}</p>}
              <hr />
              <IonButton fill="outline" href="/dashboard" className="ion-margin-top ion-padding">
                {t('btn.goToDashboard')}
              </IonButton>
            </IonCol>
          </IonRow>
        </IonGrid>
        <FooterCommon />
      </IonContent>
    </IonPage>
  )
}

export default ActionPage
