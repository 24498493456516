import React, { useEffect, useRef } from 'react'
import {
  IonCard,
  IonCardContent,
  IonCardHeader,
  IonCardTitle,
  IonLabel,
  IonSpinner,
  IonButton,
  IonText,
} from '@ionic/react'
import showdown from 'showdown'
import { AgreementType } from '../types/types'
import { getAgreementLabels } from '../constants/defaults'
import { Trans, useTranslation } from 'react-i18next'
import { useAgreementContent } from '../hooks/useAgreementContent'
import useContactEmail from '../hooks/useContactEmail'

interface AgreementContentProps {
  agreementType: AgreementType
  useCard?: boolean
  onLoadComplete?: (agreementType: AgreementType, hasError: boolean) => void
}

const AgreementContent: React.FC<AgreementContentProps> = ({ agreementType, useCard = true, onLoadComplete }) => {
  const { t } = useTranslation()
  const converter = new showdown.Converter()
  const agreementLabels = getAgreementLabels(t)
  const maxRetries = 3
  const contactEmail = useContactEmail()

  const { agreementContent, isLoading, error, loadedInDefaultLanguage, retries, getAgreement } = useAgreementContent({
    agreementType,
    maxRetries,
  })

  const hasCalledLoadCompleteRef = useRef(false)
  useEffect(() => {
    if (!isLoading && !hasCalledLoadCompleteRef.current) {
      onLoadComplete?.(agreementType, error)
      hasCalledLoadCompleteRef.current = true
    }
  }, [isLoading, error, agreementType, onLoadComplete])

  useEffect(() => {
    hasCalledLoadCompleteRef.current = false
  }, [agreementType])

  const cardContent = (
    <>
      {isLoading ? (
        <div className="ion-text-center">
          <IonSpinner color="primary" />
          <p>{t('loading.loadingAgreement')}</p>
        </div>
      ) : error ? (
        <div className="ion-text-center">
          <p className="ion-padding-bottom">
            <IonLabel color="danger">{t('agreement.errorLoading')}</IonLabel>
          </p>
          <p className="ion-padding-bottom">
            <IonLabel color="danger">{t('errors.connectionProblemDesc')}</IonLabel>
          </p>
          {retries < maxRetries ? (
            <IonButton onClick={getAgreement}>{t('btn.retry')}</IonButton>
          ) : (
            <p>
              <IonText color="danger">
                <Trans
                  i18nKey="agreement.maxRetriesReached"
                  components={{
                    1: (
                      <a href={`mailto:${contactEmail}`} target="_blank" rel="noreferrer">
                        {contactEmail}
                      </a>
                    ),
                  }}
                />
              </IonText>
            </p>
          )}
        </div>
      ) : (
        <>
          {loadedInDefaultLanguage && (
            <p className="ion-text-center text-sm ion-padding-bottom">
              <IonText color="warning">
                <em>{t('agreement.loadedInDefaultLanguage')}</em>
              </IonText>
            </p>
          )}
          <div dangerouslySetInnerHTML={{ __html: converter.makeHtml(agreementContent) }} />
        </>
      )}
    </>
  )
  if (!useCard) {
    return (
      <>
        <h1 className="ion-text-center ion-padding-bottom">{agreementLabels[agreementType].accept}</h1>
        {cardContent}
      </>
    )
  }

  return (
    <IonCard className="ion-no-margin">
      <IonCardHeader>
        <IonCardTitle className="ion-text-center">
          <h4>{agreementLabels[agreementType].accept}</h4>
        </IonCardTitle>
      </IonCardHeader>
      <IonCardContent className="ion-padding">{cardContent}</IonCardContent>
    </IonCard>
  )
}

export default AgreementContent
