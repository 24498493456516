// src/utils/getBearerToken.ts

import { DataType, SecureStorage } from '@aparajita/capacitor-secure-storage'

/**
 * Get the bearer token from the secure storage
 * @returns {Promise<string>} - The bearer token
 */
export default async function getBearerToken(): Promise<string> {
  const token: DataType | null = await SecureStorage.get('serveNowAuth')
  if (typeof token == 'string') {
    return token
  } else {
    return ''
  }
}
