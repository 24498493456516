// src/store/redux/slices/profileSlice.ts

import { WritableDraft } from 'immer'
import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit'
import { Profile, ProfileOptions, ProjectLeaderPermissions, User } from '../../../types/interfaces'
import { ProfileAPI } from '../../../api/ProfileAPI'
import { defaultUser } from '../../../constants/defaults'
import { AppState } from '../types'
import { getRegionCode } from '../utils/getRegionCode'
import { getSelectedLanguage } from '../utils/getSelectedLanguage'
import { PURGE } from 'redux-persist/es/constants'
import { setUser } from './authSlice'

export interface ProfileState {
  profile: User
  profileOptions: ProfileOptions | null
  projectLeaderPermissions: ProjectLeaderPermissions | null
  isLoading: boolean
  isSaving: boolean
  error: string | null
}

export const initialState: ProfileState = {
  profile: defaultUser,
  profileOptions: null,
  projectLeaderPermissions: null,
  isLoading: false,
  isSaving: false,
  error: null,
}

/**
 * Fetch the profile for the current user
 * @returns {Promise<User>} - The profile for the current user
 */
export const fetchProfile = createAsyncThunk<User, void>(
  'profile/fetchProfile',
  async (_, { getState, rejectWithValue, dispatch }) => {
    const regionCode = getRegionCode(getState() as AppState)
    const profileAPI: ProfileAPI = new ProfileAPI(regionCode)
    try {
      const user = await profileAPI.getProfile()
      dispatch(setUser(user))
      return user
    } catch (error) {
      return rejectWithValue(error)
    }
  },
)

/**
 * Update the profile for the current user
 * @param {Partial<Profile>} profileData - The profile data to update
 * @returns {Promise<User>} - The updated user
 */
export const updateProfile = createAsyncThunk<User, Partial<Profile>>(
  'profile/updateProfile',
  async (profileData: Partial<Profile>, { getState, rejectWithValue }) => {
    const regionCode = getRegionCode(getState() as AppState)
    const profileAPI: ProfileAPI = new ProfileAPI(regionCode)
    try {
      const response: boolean = await profileAPI.updateProfile(profileData)
      if (response) {
        return await profileAPI.getProfile()
      } else {
        return rejectWithValue('Failed to update profile')
      }
    } catch (error) {
      return rejectWithValue(error)
    }
  },
)

/**
 * Fetch the profile options
 * @returns {Promise<ProfileOptions>} - The profile options
 */
export const fetchProfileOptions = createAsyncThunk<ProfileOptions, void>(
  'profile/fetchProfileOptions',
  async (_, { getState, rejectWithValue }) => {
    const regionCode = getRegionCode(getState() as AppState)
    const selectedLanguage = getSelectedLanguage(getState() as AppState)
    const profileAPI: ProfileAPI = new ProfileAPI(regionCode, selectedLanguage)
    try {
      return await profileAPI.getProfileOptions()
    } catch (error) {
      return rejectWithValue(error)
    }
  },
)

/**
 * Fetch the project leader permissions for the current user
 * @returns {Promise<ProjectLeaderPermissions>} - The project leader permissions
 */
export const fetchPermissionsPL = createAsyncThunk<ProjectLeaderPermissions, void>(
  'profile/fetchPermissionsPL',
  async (_, { getState, rejectWithValue }) => {
    const regionCode = getRegionCode(getState() as AppState)
    const profileAPI: ProfileAPI = new ProfileAPI(regionCode)
    try {
      return await profileAPI.getPermissionsPL()
    } catch (error) {
      return rejectWithValue(error)
    }
  },
)

/**
 * Delete the profile for the current user
 * @returns {Promise<string>} - The response from the API
 */
export const deleteProfile = createAsyncThunk<string, void>(
  'profile/deleteProfile',
  async (_, { getState, rejectWithValue }) => {
    const regionCode = getRegionCode(getState() as AppState)
    const profileAPI: ProfileAPI = new ProfileAPI(regionCode)
    try {
      return await profileAPI.deleteProfile()
    } catch (error) {
      return rejectWithValue(error)
    }
  },
)

export const profileSlice = createSlice({
  name: 'profile',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(PURGE, () => initialState)
      .addCase(fetchProfile.pending, (state: WritableDraft<ProfileState>): void => {
        state.isLoading = true
      })
      .addCase(fetchProfile.fulfilled, (state: WritableDraft<ProfileState>, action: PayloadAction<User>): void => {
        state.profile = action.payload
        state.isLoading = false
      })
      .addCase(fetchProfile.rejected, (state: WritableDraft<ProfileState>, action): void => {
        state.error = (action.error.message as string) || null
        state.isLoading = false
      })

      .addCase(updateProfile.pending, (state: WritableDraft<ProfileState>): void => {
        state.isSaving = true
      })
      .addCase(updateProfile.fulfilled, (state: WritableDraft<ProfileState>, action: PayloadAction<User>): void => {
        state.profile = action.payload
        state.isSaving = false
      })
      .addCase(updateProfile.rejected, (state: WritableDraft<ProfileState>, action): void => {
        state.error = (action.error.message as string) || null
        state.isSaving = false
      })

      .addCase(fetchProfileOptions.pending, (state: WritableDraft<ProfileState>): void => {
        state.isLoading = true
      })
      .addCase(
        fetchProfileOptions.fulfilled,
        (state: WritableDraft<ProfileState>, action: PayloadAction<ProfileOptions>): void => {
          state.profileOptions = action.payload
          state.isLoading = false
        },
      )
      .addCase(fetchProfileOptions.rejected, (state: WritableDraft<ProfileState>, action): void => {
        state.error = (action.error.message as string) || null
        state.isLoading = false
      })
      .addCase(fetchPermissionsPL.pending, (state: WritableDraft<ProfileState>): void => {
        state.isLoading = true
      })
      .addCase(
        fetchPermissionsPL.fulfilled,
        (state: WritableDraft<ProfileState>, action: PayloadAction<ProjectLeaderPermissions>): void => {
          state.projectLeaderPermissions = action.payload
          state.isLoading = false
        },
      )
      .addCase(fetchPermissionsPL.rejected, (state: WritableDraft<ProfileState>, action): void => {
        state.error = (action.error.message as string) || null
        state.isLoading = false
      })

      .addCase(deleteProfile.pending, (state: WritableDraft<ProfileState>): void => {
        state.isSaving = true
      })
      .addCase(deleteProfile.fulfilled, (state: WritableDraft<ProfileState>): void => {
        state.profile = defaultUser
        state.isSaving = false
      })
      .addCase(deleteProfile.rejected, (state: WritableDraft<ProfileState>, action): void => {
        state.error = (action.error.message as string) || null
        state.isSaving = false
      })
  },
})

export default profileSlice.reducer
