// src/store/themeContext.tsx
import React, { createContext, useState, useContext, useEffect } from 'react'
import { getPreference, setPreference } from '../../utils/preferencesStorage'
import { Capacitor } from '@capacitor/core'
import { StatusBar, Style } from '@capacitor/status-bar'

interface ThemeContextProps {
  darkMode: boolean
  toggleDarkMode: (isDarkMode: boolean) => void
}

const ThemeContext = createContext<ThemeContextProps>({
  darkMode: false,
  toggleDarkMode: () => {
    // Default implementation
  },
})

type Props = {
  children: React.ReactNode
}

const ThemeProvider: React.FC<Props> = ({ children }) => {
  const [darkMode, setDarkMode] = useState<boolean>(false)

  const toggleDarkMode = async (isDarkMode: boolean) => {
    document.body.classList.toggle('dark', isDarkMode)
    setDarkMode(isDarkMode)

    // Status Bar on iOS
    if (Capacitor.getPlatform() === 'ios') {
      await StatusBar.setStyle({ style: isDarkMode ? Style.Dark : Style.Light })
    }

    await setPreference('serveNowDarkMode', isDarkMode.toString())
  }

  useEffect(() => {
    const initialiseTheme = async () => {
      const storedThemePreference = await getPreference('serveNowDarkMode')
      const isDarkMode = storedThemePreference === 'true'
      document.body.classList.toggle('dark', isDarkMode)
      setDarkMode(isDarkMode)
    }
    initialiseTheme().then((r) => r)
  }, [])

  return <ThemeContext.Provider value={{ darkMode, toggleDarkMode }}>{children}</ThemeContext.Provider>
}

const useTheme = () => useContext(ThemeContext)

export { useTheme, ThemeProvider }
