import { useEffect, useRef } from 'react'
import { ConfigState, fetchConfig } from '../store/redux/slices/configSlice'
import { useAppDispatch } from './reduxHooks'
import { useSelector } from 'react-redux'
import { AppState } from '../store/redux/types'
import { RegionState } from '../store/redux/slices/regionSlice'

const useFetchConfig = () => {
  const dispatch = useAppDispatch()
  const { apiCode } = useSelector((state: AppState): RegionState => state.region)
  const { config } = useSelector((state: AppState): ConfigState => state.config)

  const hasFetchedConfig = useRef(false)
  useEffect(() => {
    if (!config[apiCode] && !hasFetchedConfig.current) {
      dispatch(fetchConfig(apiCode))
      hasFetchedConfig.current = true
    }
  }, [dispatch, apiCode, config])
}

export default useFetchConfig
