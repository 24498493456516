// src/api/AuthAPI.ts

import { API_PATH } from '../constants/defaults'
import { User } from '../types/interfaces'
import { BaseAPI } from './BaseAPI'
import { LanguageCode } from '../types/types'

interface SignUpData {
  email: string
  password: string
  firstname: string
  lastname: string
  language: LanguageCode
  birthdate: string
  id: string
  mobile: string
  bcrypt: boolean
}

interface ActivateData {
  email: string
  token: string
}

interface ResetData {
  email: string
}

interface UpdateEmailData {
  oldEmail: string
  newEmail: string
  id: string
}

interface UpdatePasswordData {
  email: string
  password: string
  token: string
  bcrypt: boolean
}

/**
 * AuthAPI class
 * @classdesc Class for interacting with the auth API
 */
export class AuthAPI extends BaseAPI {
  /**
   * Logs in the user
   * @returns {Promise<User>} - The user
   */
  public async login(email: string, password: string): Promise<User> {
    const data = {
      email,
      password,
    }
    return this.api.post(API_PATH.LOGIN, data).then((response) => response.data)
  }

  /**
   * Signs up the user
   * @param firstname {string} - The user's first name
   * @param lastname {string} - The user's last name
   * @param language {LanguageCode} - The user's preferred language
   * @param birthdate {string} - The user's birthdate
   * @param id {string} - The user's ID
   * @param mobile {string} - The user's mobile number
   * @param email {string} - The user's email
   * @param password {string} - The user's password
   * @returns {Promise<string>} - The success message
   */
  public async signUp(
    firstname: string,
    lastname: string,
    language: LanguageCode,
    birthdate: string,
    id: string,
    mobile: string,
    email: string,
    password: string,
  ): Promise<string> {
    const data: SignUpData = {
      email,
      password,
      firstname,
      lastname,
      language,
      birthdate,
      id,
      mobile,
      bcrypt: true,
    }
    return this.api.post(API_PATH.SIGNUP, data).then((response) => response.data)
  }

  /**
   * Activates the user's account
   * @param email {string} - The user's email
   * @param token {string} - The activation token
   */
  public async activateAccount(email: string, token: string): Promise<string> {
    const data: ActivateData = {
      email,
      token,
    }
    return this.api.post(API_PATH.ACTIVATE, data).then((response) => response.data)
  }

  /**
   * Resets the user's password
   * @param email {string} - The user's email
   */
  public async resetPassword(email: string): Promise<string> {
    const data: ResetData = {
      email,
    }
    return this.api.post(API_PATH.RESET, data).then((response) => response.data)
  }

  /**
   * Updates the user's email
   * @param id {string} - The user's ID
   * @param oldEmail {string} - The user's old email
   * @param newEmail {string} - The user's new email
   */
  public async updateEmail(id: string, oldEmail: string, newEmail: string): Promise<string> {
    const data: UpdateEmailData = {
      id,
      oldEmail,
      newEmail,
    }
    return this.api.post(API_PATH.UPDATE_EMAIL, data).then((response) => response.data)
  }

  /**
   * Validates the user's token when updating the password
   * @param email {string} - The user's email
   * @param token {string} - The user's token
   */
  public async validateToken(email: string, token: string): Promise<string> {
    return this.api.get(`${API_PATH.VALIDATE_TOKEN}/${email}/${token}`).then((response) => response.data)
  }

  /**
   * Updates the user's password
   * @param email {string} - The user's email
   * @param token {string} - The user's token
   * @param password {string} - The user's password
   */
  public async updatePassword(email: string, token: string, password: string): Promise<string> {
    const data: UpdatePasswordData = {
      email,
      password,
      token,
      bcrypt: true,
    }
    return this.api.post(API_PATH.UPDATE_PASSWORD, data).then((response) => response.data)
  }
}
