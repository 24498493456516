export interface LocalConfig {
  [key: string]: string | boolean | number | undefined

  API_BASE_URL: string
  REGION: 'all' | 'spain'
  UAT: boolean
  ENV: string
}

const isValidConfig = (config: unknown): config is LocalConfig => {
  return typeof config === 'object' && config !== null
}

export const loadConfig = async (): Promise<LocalConfig> => {
  const response: Response = await fetch('/config.js')
  const scriptText: string = await response.text()

  eval(scriptText)
  const config = window.config

  if (isValidConfig(config)) {
    return config
  } else {
    throw new Error('Invalid configuration format')
  }
}
