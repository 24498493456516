/**
 * Validate Spanish DNI
 * @param dni {string} The DNI to validate
 * @param strict {boolean} Whether to use strict validation
 * @returns {boolean} True if the DNI is valid, false otherwise
 */
export const validateSpanishDNI = (dni: string, strict: boolean = false): boolean => {
  if (strict) {
    const validChars = 'TRWAGMYFPDXBNJZSQVHLCKE'
    const nifRegExp = /^[0-9]{8}[TRWAGMYFPDXBNJZSQVHLCKE]$/i
    const nieRegExp = /^[XYZ][0-9]{7}[TRWAGMYFPDXBNJZSQVHLCKE]$/i
    const str = dni.toString().toUpperCase()
    if (!nifRegExp.test(str) && !nieRegExp.test(str)) {
      return false
    }
    const nie = str.replace(/^X/, '0').replace(/^Y/, '1').replace(/^Z/, '2')
    const letter = str.substring(str.length - 1)
    const charIndex = parseInt(nie.substring(0, 8)) % 23
    return validChars.charAt(charIndex) === letter
  } else {
    const dniRegExp = /^[0-9]{7,8}[A-Z]$/i
    const nieRegExp = /^[XYZ][0-9]{7}[A-Z]$/i
    const str = dni.toString().toUpperCase()
    return dniRegExp.test(str) || nieRegExp.test(str)
  }
}
