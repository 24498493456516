// src/containers/LoginPage/AlmostThereSlide.tsx

import { IonButton, IonCol, IonGrid, IonIcon, IonRow } from '@ionic/react'
import React from 'react'
import Swiper from 'swiper'
import { Capacitor } from '@capacitor/core'
import { arrowBack } from 'ionicons/icons'
import { useTranslation } from 'react-i18next'

interface WelcomeAlmostThereSlideProps {
  swiperInstance: Swiper
  scrollToTop: () => void
}

const WelcomeAlmostThereSlide: React.FC<WelcomeAlmostThereSlideProps> = ({ swiperInstance, scrollToTop }) => {
  const { t } = useTranslation()
  return (
    <IonGrid className="loginScreen__region">
      <IonRow>
        <IonCol size-sm="8" offset-sm="2" size-md="6" offset-md="3" size-lg="4" offset-lg="4">
          {Capacitor.getPlatform() === 'ios' && <div>&nbsp;</div>}
          <h2>{t('login.reminders')}</h2>
          <img src="/assets/images/login-reminders.svg" alt="" className="login" />
        </IonCol>
      </IonRow>
      <IonRow>
        <IonCol>
          <p>{t('login.notice')}</p>
          <p>{t('login.infosession')}</p>
        </IonCol>
      </IonRow>
      <IonRow>
        <IonCol className="ion-text-center" size="12"></IonCol>
      </IonRow>
      <IonRow className="ion-padding-top fullwidth footer">
        <IonCol className="ion-text-left" size="6">
          <IonButton
            fill="clear"
            onClick={(): void => {
              swiperInstance!.slidePrev()
              scrollToTop()
            }}
          >
            {t('btn.back')} <IonIcon icon={arrowBack} slot="start" aria-hidden={true}></IonIcon>
          </IonButton>
        </IonCol>
        <IonCol className="ion-text-right" size="6">
          <IonButton
            onClick={(): void => {
              swiperInstance!.slideNext()
              scrollToTop()
            }}
          >
            {t('btn.createLogin')}{' '}
          </IonButton>
        </IonCol>
      </IonRow>
    </IonGrid>
  )
}

export default WelcomeAlmostThereSlide
