// src/hooks/useStatusBarStyle.ts

import { useEffect } from 'react'
import { Capacitor } from '@capacitor/core'
import { StatusBar, Style } from '@capacitor/status-bar'
import { useTheme } from '../store/themeContext'

const useStatusBarStyle = () => {
  const { darkMode } = useTheme()

  useEffect(() => {
    const setStatusBarStyle = async () => {
      if (Capacitor.getPlatform() === 'ios') {
        try {
          await StatusBar.setStyle({ style: darkMode ? Style.Dark : Style.Light })
        } catch (err) {
          console.error('Failed to set status bar style:', err)
        }
      }
    }
    setStatusBarStyle().then((r) => r)
  }, [darkMode])
}

export default useStatusBarStyle
